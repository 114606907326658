import React, { useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import khanda from "../Photos/khanda.png";
import { Modal, Button } from "react-bootstrap";
import heading from "../Photos/heading.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

// import { response } from "express";

function FindAMatch() {
  const history = useHistory();
  // const history = useHistory();
  const gotodashboard = () => {
    history.push("/dashboard", { verified: true });
  }
  const [CandidateID, setCandidateID] = useState("");

  const [CandidateFullName, setCandidateFullName] = useState("");
  const [candidatelastName, setCandidatelastName] = useState("");
  const [candidateSurname, setCandidateSurname] = useState("");
  const [CandidateCurrentAddress, setCandidateCurrentAddress] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [MaritalStatus, setMaritalStatus] = useState("");
  const [Religion, setReligion] = useState("");
  const [Gender, setGender] = useState("");
  const [Height, setHeight] = useState("");
  const [Weight, setWeight] = useState("");
  const [AnyDisability, setAnyDisability] = useState("");
  const [MotherTonge, setMotherTonge] = useState("");
  const [LanguagesKnown, setLanguagesKnown] = useState("");
  const [Nationality, setNationality] = useState("");
  const [WhatsappNumber, setWhatsappNumber] = useState("");
  const [Email, setEmail] = useState("");
  const [qulification, setQulification] = useState("");
  const [HighestEducation, setHighestEducation] = useState("");
  const [CandidateOccupation, setCandidateOccupation] = useState("");
  const [NatureOfWork, setNatureOfWork] = useState("");
  const [Currency, setcurrency] = useState("");
  const [CandidateAnnualIncome, setCandidateAnnualIncome] = useState("");
  const [FatherAddress, setFatherAddress] = useState("");
  const [FamilyAnnualIncome, setFamilyAnnualIncome] = useState("");
  const [GurudwaraVisited, setGurudwaraVisited] = useState("");
  const [GcontactPerson, setGcontactPerson] = useState("");
  const [GcontactNumber, setGcontactNumber] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [fatherAge, setFatherAge] = useState("");
  const [fatherEducation, setFatherEducation] = useState("");
  const [fatherDegree, setFatherDegree] = useState("");
  const [mothername, setMotherName] = useState("");
  const [motherage, setMotherAge] = useState("");
  const [motherEducation, setMotherEducation] = useState("");
  const [motherDegree, setMotherDegree] = useState("");
  const [relative3, setRelative3] = useState("");
  const [relative3name, setRelative3Name] = useState("");
  const [relative3age, setRelative3Age] = useState("");
  const [relative3Education, setRelative3Education] = useState("");
  const [relative3Degree, setRelative3Degree] = useState("");
  const [relative4, setRelative4] = useState("");
  const [relative4name, setRelative4Name] = useState("");
  const [relative4age, setRelative4Age] = useState("");
  const [relative4Education, setRelative4Education] = useState("");
  const [relative4Degree, setRelative4Degree] = useState("");
  const [relative5, setRelative5] = useState("");
  const [relative5name, setRelative5Name] = useState("");
  const [relative5age, setRelative5Age] = useState("");
  const [relative5Education, setRelative5Education] = useState("");
  const [relative5Degree, setRelative5Degree] = useState("");
  const [relative6, setRelative6] = useState("");
  const [relative6name, setRelative6Name] = useState("");
  const [relative6age, setRelative6Age] = useState("");
  const [relative6Education, setRelative6Education] = useState("");
  const [relative6Degree, setRelative6Degree] = useState("");
  const [relative7, setRelative7] = useState("");
  const [relative7name, setRelative7Name] = useState("");
  const [relative7age, setRelative7Age] = useState("");
  const [relative7Education, setRelative7Education] = useState("");
  const [relative7Degree, setRelative7Degree] = useState("");
  const [remarks, setRemarks] = useState("");
  const [referenceName, setReferenceName] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [oldreferenceNumber, setOldReferenceNumber] = useState("");

  const [recivedOtp, setRecivedOtp] = useState("312321321");
  const [otp, setOtp] = useState("312321321");

  const approveapplicant = () => {
    Axios.post("https://api-gsm.sikhfoundation.co.in/checkformodifyform", {
      CandidateID: CandidateID,
    }).then((response) => {
      if (response.data === false) {
        // alert("Wrong Applicant Id")
        setShowModal(true);
        setModalMessage("Wrong Applicant Id");
      } else {
        if (response.data === "Approved" || response.data === "Enabled") {
          // alert("you are Approved")
          setShowModal(true);
          setModalMessage("Login, to modify.");
        } else if (response.data === "Disabled") {
          setShowModal(true);
          setModalMessage("Your application is under verification. Try later.");
        } else {
          setShowModal(true);
          setModalMessage("OTP sent to your whatsapp number");
          document.getElementById("forgotidbtn").classList.add("d-none");
          setRecivedOtp(response.data);
        }
      }
    });
  };

  const check = () => {
    if (otp == recivedOtp) {
      Axios.post("https://api-gsm.sikhfoundation.co.in/recommendapplicant", {
        CandidateID: CandidateID,
      }).then((response) => {
        // setCandidatePhoto(response.data.map((val, key) => val.Candidate_Photo))
        setCandidateFullName(
          response.data.map((val, key) => val.candidate_firstname)
        );
        setCandidatelastName(
          response.data.map((val, key) => val.candidate_middlename)
        );
        setCandidateSurname(
          response.data.map((val, key) => val.candidate_lastname)
        );
        setCandidateCurrentAddress(
          response.data.map((val, key) => val.Candidate_Current_Address)
        );
        setDateOfBirth(
          response.data.map((val, key) => {
            const date = new Date(val.Date_of_Birth);
            return date.toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            });
          })
        );
        setMaritalStatus(response.data.map((val, key) => val.Marital_Status));
        setReligion(response.data.map((val, key) => val.Religion));
        setGender(response.data.map((val, key) => val.Gender));
        setHeight(response.data.map((val, key) => val.height_cm));
        setWeight(response.data.map((val, key) => val.Weight));
        setAnyDisability(response.data.map((val, key) => val.Any_Disability));
        setMotherTonge(response.data.map((val, key) => val.Mother_Tongue));
        setLanguagesKnown(response.data.map((val, key) => val.Languages_Known));
        setNationality(response.data.map((val, key) => val.Nationality));
        setWhatsappNumber(response.data.map((val, key) => val.WhatsApp_Number));
        setEmail(response.data.map((val, key) => val.Email_ID));
        setQulification(response.data.map((val, key) => val.Qualification));
        // setDegree(response.data.map((val, key) => val.Qualification));
        setHighestEducation(response.data.map((val, key) => val.Degree));
        setCandidateOccupation(
          response.data.map((val, key) => val.Candidate_Occupation)
        );
        setNatureOfWork(response.data.map((val, key) => val.Nature_Of_Work));
        setcurrency(response.data.map((val, key) => val.Currency));
        setCandidateAnnualIncome(
          response.data.map((val, key) => val.Candidate_Annual_Income)
        );
        setFatherAddress(
          response.data.map((val, key) => val.family_permanent_address)
        );
        setFamilyAnnualIncome(
          response.data.map((val, key) => val.Family_Annual_Income)
        );
        setGurudwaraVisited(
          response.data.map((val, key) => val.Gurudwara_Normally_visited)
        );
        setGcontactPerson(
          response.data.map((val, key) => val.G_Contact_Person)
        );
        setGcontactNumber(response.data.map((val, key) => val.G_Phone_Number));
        setRemarks(response.data.map((val, key) => val.additional_information));
        setReferenceName(response.data.map((val, key) => val.Reference_Name));
        setReferenceNumber(response.data.map((val, key) => val.Reference_Contact.substring(2)));
        setOldReferenceNumber(response.data.map((val, key) => val.Reference_Contact.substring(2)));
        console.log(response.data.map((val, key) => val.Candidate_Photo));

        Axios.post("https://api-gsm.sikhfoundation.co.in/familydata", {
          CandidateID: CandidateID,
        }).then((response) => {
          setFatherName(response.data.map((val, key) => val.Relative_name)[0]);
          setFatherAge(response.data.map((val, key) => val.Relative_age)[0]);
          setFatherEducation(
            response.data.map((val, key) => val.Relative_Education)[0]
          );
          setFatherDegree(
            response.data.map((val, key) => val.Relative_Degree)[0]
          );
          setMotherName(response.data.map((val, key) => val.Relative_name)[1]);
          setMotherAge(response.data.map((val, key) => val.Relative_age)[1]);
          setMotherEducation(
            response.data.map((val, key) => val.Relative_Education)[1]
          );
          setMotherDegree(
            response.data.map((val, key) => val.Relative_Degree)[1]
          );
          setRelative3(response.data.map((val, key) => val.Relationship)[2]);
          setRelative3Name(
            response.data.map((val, key) => val.Relative_name)[2]
          );
          setRelative3Age(response.data.map((val, key) => val.Relative_age)[2]);
          setRelative3Education(
            response.data.map((val, key) => val.Relative_Education)[2]
          );
          setRelative3Degree(
            response.data.map((val, key) => val.Relative_Degree)[2]
          );
          setRelative4(response.data.map((val, key) => val.Relationship)[3]);
          setRelative4Name(
            response.data.map((val, key) => val.Relative_name)[3]
          );
          setRelative4Age(response.data.map((val, key) => val.Relative_age)[3]);
          setRelative4Education(
            response.data.map((val, key) => val.Relative_Education)[3]
          );
          setRelative4Degree(
            response.data.map((val, key) => val.Relative_Degree)[3]
          );
          setRelative5(response.data.map((val, key) => val.Relationship)[4]);
          setRelative5Name(
            response.data.map((val, key) => val.Relative_name)[4]
          );
          setRelative5Age(response.data.map((val, key) => val.Relative_age)[4]);
          setRelative5Education(
            response.data.map((val, key) => val.Relative_Education)[4]
          );
          setRelative5Degree(
            response.data.map((val, key) => val.Relative_Degree)[4]
          );
          setRelative6(response.data.map((val, key) => val.Relationship)[5]);
          setRelative6Name(
            response.data.map((val, key) => val.Relative_name)[5]
          );
          setRelative6Age(response.data.map((val, key) => val.Relative_age)[5]);
          setRelative6Education(
            response.data.map((val, key) => val.Relative_Education)[5]
          );
          setRelative6Degree(
            response.data.map((val, key) => val.Relative_Degree)[5]
          );
          setRelative7(response.data.map((val, key) => val.Relationship)[6]);
          setRelative7Name(
            response.data.map((val, key) => val.Relative_name)[6]
          );
          setRelative7Age(response.data.map((val, key) => val.Relative_age)[6]);
          setRelative7Education(
            response.data.map((val, key) => val.Relative_Education)[6]
          );
          setRelative7Degree(
            response.data.map((val, key) => val.Relative_Degree)[6]
          );
          // console.log("valueset" , (response.data.map((val, key) => val.))[1]);
        });
      });
      // alert("otp true");
      // console.log("otp true");
      document.getElementById("formform").hidden = false;
      document.getElementById("otpotp").hidden = true;
    } else {
      // document.getElementById("exampleModal123").hidden = false;
      console.log("false");
      // alert("Incorrect try again");
      setShowModal(true);
      setModalMessage("Incorrect try again");
    }
  };

  let countofsendingreference = 0;

  const saveapplicant = async () => {
    if (true) {
      Axios.post("https://api-gsm.sikhfoundation.co.in/updateotherthings", {
        CandidateID: CandidateID,
        CandidateFullName: CandidateFullName,
        candidatelastName: candidatelastName,
        candidateSurname: candidateSurname,
        CandidateCurrentAddress: CandidateCurrentAddress,
        MaritalStatus: MaritalStatus,
        Religion: Religion,
        Gender: Gender,
        Height: Height,
        Weight: Weight,
        AnyDisability: AnyDisability,
        MotherTonge: MotherTonge,
        LanguagesKnown: LanguagesKnown,
        Nationality: Nationality,
        Email: Email,
        qulification: qulification,
        HighestEducation: HighestEducation,
        CandidateOccupation: CandidateOccupation,
        NatureOfWork: NatureOfWork,
        Currency: Currency,
        CandidateAnnualIncome: CandidateAnnualIncome,
        FatherAddress: FatherAddress,
        FamilyAnnualIncome: FamilyAnnualIncome,
        GurudwaraVisited: GurudwaraVisited,
        GcontactPerson: GcontactPerson,
        GcontactNumber: GcontactNumber,
        remarks: remarks,
        referenceName: referenceName,
        referenceNumber: referenceNumber,
        oldreferenceNumber: oldreferenceNumber,
        WhatsappNumber: WhatsappNumber,
      });
    }
    if (oldreferenceNumber.toString() == referenceNumber.toString()) {
      countofsendingreference = countofsendingreference + 1;
    }
    console.log(countofsendingreference);
    history.push({
      pathname: "/uploadformphoto",
      state: {
        CandidateID,
        phoneNo: WhatsappNumber,
        CandidateFullName,
        candidatelastName,
        candidateSurname,
        referenceName,
        referenceNumber,
        Gender,
        isEdit: true,
        countofsendingreference,
      },
    });
  };

  const [modalMessage, setModalMessage] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container">
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>GSM says</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          {modalMessage ==
            "Form submitted successfully. Applicant Id sent to your Whatsapp number. Login-password will be sent when the profile is approved." ? (
            <Link
              className="btn btn-primary"
              onClick={gotodashboard}
            >
              OK
            </Link>
          ) : (
            <Button variant="primary" onClick={closeModal} autoFocus>
              Ok
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <div
        className="mt-2 text-primary text-center sticky-top border-bottom border-primary border-2"
        style={{ backgroundColor: "white" }}
      >
        <img src={heading} alt="" class="col-md-5 text-center img-fluid" />
      </div>
      <Link className="my-3 btn btn-primary" onClick={gotodashboard}>
        <i class="bi bi-caret-left-fill mx-2"></i> Back
      </Link>
      <Link className="my-3 mx-2 btn btn-danger" id="forgotidbtn" to="/resendapplicantid">Forgot applicant-ID</Link>

      <div className="row g-3" id="otpotp">
        <div className="col-md-3">
          <input
            className="form-control"
            id="input"
            type="number"
            placeholder="Enter Applicant ID"
            onChange={(event) => {
              setCandidateID(event.target.value);
            }}
            autoComplete="off"
            autoFocus
          />
        </div>
        <div className="col-md-9 ">
          <Link
            to="/EditApplicationForm"
            id="otp"
            className="btn btn-primary"
            onClick={approveapplicant}
          >
            Get OTP
          </Link>
        </div>
        <div className="col-md-3">
          <input
            className="form-control"
            type="number"
            placeholder="Enter OTP"
            onChange={(event) => {
              setOtp(event.target.value);
            }}
          />
        </div>
        <div className="col-md-3 mb-4">
          <Link
            to="/EditApplicationForm"
            className="btn btn-primary"
            onClick={check}
          >
            Verify OTP
          </Link>
        </div>
      </div>

      <div className="m-3">
        {/* <div className="mx-3 mt-4">
                    <form>
                        <div className="col-md-3">
                            <label className="form-label">Candidate ID</label>
                            <input type="text" className="form-control" onChange={(event) => { setCandidateID(event.target.value) }} />
                        </div>
                        <button type="button" className="btn btn-primary mt-3" onClick={approveapplicant}>Show Profile</button>
                        <div className="col-md-3">
                            <label className="form-label">otp</label>
                            <input type="text" className="form-control" onChange={(event) => { setOtp(event.target.value) }} />
                        </div>
                        <button type="button" className="btn btn-primary mt-3" onClick={check}>Show Profile</button>
                    </form>
                </div> */}

        <form
          onLoad="genCandidateID"
          className="row g-3"
          action=""
          method="post"
          id="formform"
          hidden
        >
          {/* <div className="col-md-4">
                        <label className="form-label fs-6">Candidate's Full Name</label>
                        <input className="form-control fs-5" type="text" value={CandidateFullName} onChange={(event) => { setCandidateFullName(event.target.value) }} />
                    </div> */}
          <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary border-3">
            Application Form
          </div>

          {/* line 1 */}
          <div className="col-md-4">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Applicant's First Name
            </label>
            <input
              className="form-control"
              type="text"
              value={CandidateFullName}
              onChange={(event) => {
                setCandidateFullName(event.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Applicant's Middle Name
            </label>
            <input
              className="form-control"
              type="text"
              value={candidatelastName}
              onChange={(event) => {
                setCandidatelastName(event.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Applicant's Last Name
            </label>
            <input
              className="form-control"
              type="text"
              value={candidateSurname}
              onChange={(event) => {
                setCandidateSurname(event.target.value);
              }}
            />
          </div>

          <div className="col-md-12">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Applicant's Current Address
            </label>
            <input
              className="form-control"
              type="text"
              value={CandidateCurrentAddress}
              onChange={(event) => {
                setCandidateCurrentAddress(event.target.value);
              }}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Date of Birth
            </label>
            <input
              className="form-control"
              type="text"
              value={DateOfBirth}
              onChange={(event) => {
                setDateOfBirth(event.target.value);
              }}
              disabled
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom04" className="form-label fs-5">
              Marital Status
            </label>
            <select
              className="form-select"
              onChange={(event) => {
                setMaritalStatus(event.target.value);
              }}
            >
              <option value="DEFAULT" selected disabled>
                {MaritalStatus}
              </option>
              <option>Never married</option>
              <option>Divorcee</option>
              <option>Widow/Widower</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom04" className="form-label fs-5">
              Religion
            </label>
            <select
              className="form-select"
              onChange={(event) => {
                setReligion(event.target.value);
              }}
            >
              <option value="DEFAULT" selected disabled>
                {Religion}
              </option>
              <option>Sikh (Keshdhari)</option>
              <option>Sikh (Amritdhari)</option>
              <option>Sikh</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom04" className="form-label fs-5">
              Gender
            </label>
            <select
              className="form-select"
              onChange={(event) => {
                setGender(event.target.value);
              }}
            >
              <option value="DEFAULT" selected disabled>
                {Gender}
              </option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>
          <div className="col-md-4">
            <label
              htmlFor="validationCustomUsername"
              className="form-label fs-5"
            >
              Height
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupPrepend">
                cm
              </span>
              <input
                type="number"
                className="form-control"
                aria-describedby="inputGroupPrepend"
                value={Height}
                onChange={(event) => {
                  setHeight(event.target.value);
                }}
              />
              <div className="invalid-feedback">Please choose a username.</div>
            </div>
          </div>

          <div className="col-md-4">
            <label
              htmlFor="validationCustomUsername"
              className="form-label fs-5"
            >
              Weight
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupPrepend">
                kg
              </span>
              <input
                type="number"
                className="form-control"
                aria-describedby="inputGroupPrepend"
                value={Weight}
                onChange={(event) => {
                  setWeight(event.target.value);
                }}
              />
              <div className="invalid-feedback">Please choose a username.</div>
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Any Disability?
            </label>
            <input
              className="form-control"
              type="text"
              value={AnyDisability}
              onChange={(event) => {
                setAnyDisability(event.target.value);
              }}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Mother Tongue
            </label>
            <input
              className="form-control"
              type="text"
              value={MotherTonge}
              onChange={(event) => {
                setMotherTonge(event.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Languages Known
            </label>
            <input
              className="form-control"
              type="text"
              value={LanguagesKnown}
              onChange={(event) => {
                setLanguagesKnown(event.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Nationality
            </label>
            <input
              className="form-control"
              type="text"
              value={Nationality}
              onChange={(event) => {
                setNationality(event.target.value);
              }}
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Verified Phone Number
            </label>
            <input
              className="form-control"
              type="number"
              disabled
              value={WhatsappNumber}
              onChange={(event) => {
                setWhatsappNumber(event.target.value);
              }}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Email-ID
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupPrepend">
                @
              </span>
              <input
                className="form-control"
                type="email"
                aria-describedby="inputGroupPrepend"
                value={Email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <div className="invalid-feedback">Please choose a username.</div>
            </div>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom04" className="form-label fs-5">
              Education
            </label>
            <select
              className="form-select"
              onChange={(event) => {
                setQulification(event.target.value);
              }}
            >
              <option value="DEFAULT" selected disabled>
                {qulification}
              </option>
              <option>Under Graduate</option>
              <option>Graduate</option>
              <option>Post Graduate</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Highest Degree
            </label>
            <input
              className="form-control"
              type="text"
              value={HighestEducation}
              onChange={(event) => {
                setHighestEducation(event.target.value);
              }}
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Applicant's Occupation
            </label>
            <select
              className="form-select"
              onChange={(event) => {
                setCandidateOccupation(event.target.value);
              }}
            >
              <option value="DEFAULT" selected disabled>
                {CandidateOccupation}
              </option>
              <option>Businessman</option>
              <option>Professional</option>
              <option>Govt. Service</option>
              <option>Private Service</option>
              <option>Serving Abroad</option>
              <option>Other</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Nature Of Work
            </label>
            <select
              className="form-select"
              onChange={(event) => {
                setNatureOfWork(event.target.value);
              }}
            >
              <option value="DEFAULT" selected disabled>
                {NatureOfWork}
              </option>
              <option>IT</option>
              <option>Medical</option>
              <option>Engineering</option>
              <option>Other</option>
            </select>
          </div>
          <div className="col-md-2">
            <label
              htmlFor="validationCustomUsername"
              className="form-label fs-5"
            >
              Currency
            </label>
            <select
              className="form-select"
              onChange={(event) => {
                setcurrency(event.target.value);
              }}
            >
              <option value="DEFAULT" selected disabled>
                {Currency}
              </option>
              <option>INR - ₹</option>
              <option>AUD - $</option>
              <option>CAD - $</option>
              <option>LKR - ₨</option>
              <option>ARS - $</option>
              <option>BDT - ৳</option>
              <option>BTN - Nu.</option>
              <option>AFN - ؋</option>
              <option>BRL - R$</option>
              <option>KHR - ៛</option>
              <option>CNY - ¥</option>
              <option>COP - $</option>
              <option>DKK - kr</option>
              <option>EGP - £</option>
              <option>HKD - $</option>
              <option>IDR - Rp</option>
              <option>IRR - ﷼</option>
              <option>ILS - ₪</option>
              <option>JPY - ¥</option>
              <option>JEP - £</option>
              <option>KPW - ₩</option>
              <option>KRW - ₩</option>
              <option>LRD - $</option>
              <option>MYR - RM</option>
              <option>MXN - $</option>
              <option>NPR - ₨</option>
              <option>NGN - ₦</option>
              <option>NOK - kr</option>
              <option>OMR - ﷼</option>
              <option>PKR - ₨</option>
              <option>PHP - ₱</option>
              <option>PLN - zł</option>
              <option>QAR - ﷼</option>
              <option>RUB - ₽</option>
              <option>SAR - ﷼</option>
              <option>RSD - Дин.</option>
              <option>SGD - $</option>
              <option>ZAR - R</option>
              <option>SEK - kr</option>
              <option>CHF - CHF</option>
              <option>TWD - NT$</option>
              <option>THB - ฿</option>
              <option>UAH - ₴</option>
              <option>GBP - £</option>
              <option>YER - ﷼</option>
              <option>ZWD - Z$</option>
            </select>
          </div>
          <div className="col-md-3">
            <label
              htmlFor="validationCustomUsername"
              className="form-label fs-5"
            >
              Applicant's Annual Income
            </label>
            <div className="input-group has-validation">
              <input
                type="number"
                className="form-control"
                id="validationCustomUsername"
                aria-describedby="inputGroupPrepend"
                value={CandidateAnnualIncome}
                onChange={(event) => {
                  setCandidateAnnualIncome(event.target.value);
                }}
              />
              <div className="invalid-feedback">Please choose a username.</div>
            </div>
          </div>

          <div className="mt-3">
            <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary">
              Family & Relatives
            </div>

            <div className="row g-3">
              <div className="col-md-2">
                <input
                  className="form-control"
                  type="text"
                  value="Father"
                  disabled
                />
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  value={fatherName}
                  onChange={(event) => {
                    setFatherName(event.target.value);
                  }}
                  disabled
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  value={fatherAge}
                  onChange={(event) => {
                    setFatherAge(event.target.value);
                  }}
                  disabled
                />
              </div>
              <div className="col-md-2">
                <select
                  className="form-select"
                  value={fatherEducation}
                  onChange={(event) => {
                    setFatherEducation(event.target.value);
                  }}
                  disabled
                >
                  <option selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-select"
                  value={fatherDegree}
                  onChange={(event) => {
                    setFatherDegree(event.target.value);
                  }}
                  disabled
                >
                  <option selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              <hr className="d-md-none" />

              {/* line 2 */}
              <div className="col-md-2">
                <input
                  className="form-control"
                  type="text"
                  value="Mother"
                  disabled
                />
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  value={mothername}
                  onChange={(event) => {
                    setMotherName(event.target.value);
                  }}
                  disabled
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  value={motherage}
                  onChange={(event) => {
                    setMotherAge(event.target.value);
                  }}
                  disabled
                />
              </div>
              <div className="col-md-2">
                <select
                  className="form-select"
                  value={motherEducation}
                  onChange={(event) => {
                    setMotherEducation(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-select"
                  value={motherDegree}
                  onChange={(event) => {
                    setMotherDegree(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              <hr className="d-md-none" />

              <div className="col-md-2">
                <select
                  className="form-select"
                  value={relative3}
                  onChange={(event) => {
                    setRelative3(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Brother / Sister
                  </option>
                  <option>Brother</option>
                  <option>Sister</option>
                </select>
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  value={relative3name}
                  onChange={(event) => {
                    setRelative3Name(event.target.value);
                  }}
                  disabled
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  value={relative3age}
                  onChange={(event) => {
                    setRelative3Age(event.target.value);
                  }}
                  disabled
                />
              </div>
              <div className="col-md-2">
                <select
                  className="form-select"
                  value={relative3Education}
                  onChange={(event) => {
                    setRelative3Education(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-select"
                  value={relative3Degree}
                  onChange={(event) => {
                    setRelative3Degree(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              <hr className="d-md-none" />

              <div className="col-md-2">
                <select
                  className="form-select"
                  value={relative4}
                  onChange={(event) => {
                    setRelative4(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Brother / Sister
                  </option>
                  <option>Brother</option>
                  <option>Sister</option>
                </select>
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  value={relative4name}
                  onChange={(event) => {
                    setRelative4Name(event.target.value);
                  }}
                  disabled
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  value={relative4age}
                  onChange={(event) => {
                    setRelative4Age(event.target.value);
                  }}
                  disabled
                />
              </div>
              <div className="col-md-2">
                <select
                  className="form-select"
                  value={relative4Education}
                  onChange={(event) => {
                    setRelative4Education(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-select"
                  value={relative4Degree}
                  onChange={(event) => {
                    setRelative4Degree(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              <hr className="d-md-none" />

              <div className="col-md-2">
                <select
                  className="form-select"
                  value={relative5}
                  onChange={(event) => {
                    setRelative5(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Brother / Sister
                  </option>
                  <option>Brother</option>
                  <option>Sister</option>
                </select>
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  value={relative5name}
                  onChange={(event) => {
                    setRelative5Name(event.target.value);
                  }}
                  disabled
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  value={relative5age}
                  onChange={(event) => {
                    setRelative5Age(event.target.value);
                  }}
                  disabled
                />
              </div>
              <div className="col-md-2">
                <select
                  className="form-select"
                  value={relative5Education}
                  onChange={(event) => {
                    setRelative5Education(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-select"
                  value={relative5Degree}
                  onChange={(event) => {
                    setRelative5Degree(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              <hr className="d-md-none" />

              <div className="col-md-2">
                <select
                  className="form-select"
                  value={relative6}
                  onChange={(event) => {
                    setRelative6(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Brother / Sister
                  </option>
                  <option>Brother</option>
                  <option>Sister</option>
                </select>
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  value={relative6name}
                  onChange={(event) => {
                    setRelative6Name(event.target.value);
                  }}
                  disabled
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  value={relative6age}
                  onChange={(event) => {
                    setRelative6Age(event.target.value);
                  }}
                  disabled
                />
              </div>
              <div className="col-md-2">
                <select
                  className="form-select"
                  value={relative6Education}
                  onChange={(event) => {
                    setRelative6Education(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-select"
                  value={relative6Degree}
                  onChange={(event) => {
                    setRelative6Degree(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              <hr className="d-md-none" />

              <div className="col-md-2">
                <select
                  className="form-select"
                  value={relative7}
                  onChange={(event) => {
                    setRelative7(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    {" "}
                    Brother / Sister{" "}
                  </option>
                  <option>Brother</option>
                  <option>Sister</option>
                </select>
              </div>
              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  value={relative7name}
                  onChange={(event) => {
                    setRelative7Name(event.target.value);
                  }}
                  disabled
                />
              </div>
              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  value={relative7age}
                  onChange={(event) => {
                    setRelative7Age(event.target.value);
                  }}
                  disabled
                />
              </div>
              <div className="col-md-2">
                <select
                  className="form-select"
                  value={relative7Education}
                  onChange={(event) => {
                    setRelative7Education(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                <select
                  className="form-select"
                  value={relative7Degree}
                  onChange={(event) => {
                    setRelative7Degree(event.target.value);
                  }}
                  disabled
                >
                  <option value="DEFAULT" selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              <hr className="d-md-none" />

              <div className="col-md-9">
                <label htmlFor="validationCustom02" className="form-label fs-5">
                  Father's / Family's Permanent Address
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={FatherAddress}
                  onChange={(event) => {
                    setFatherAddress(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-3">
                <label
                  htmlFor="validationCustomUsername"
                  className="form-label fs-5"
                >
                  Family's Annual Income
                </label>
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">
                    ₹
                  </span>
                  <input
                    type="number"
                    className="form-control"
                    id="validationCustomUsername"
                    aria-describedby="inputGroupPrepend"
                    value={FamilyAnnualIncome}
                    onChange={(event) => {
                      setFamilyAnnualIncome(event.target.value);
                    }}
                  />
                  <div className="invalid-feedback">
                    Please choose a username.
                  </div>
                </div>
              </div>

              <div className="h4 pb-2 mb-1 text-primary border-bottom border-primary">
                Gurudwara Details
              </div>

              <div className="col-md-6">
                <label htmlFor="validationCustom02" className="form-label ">
                  Gurudwara Normally visited : Name & Address
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={GurudwaraVisited}
                  onChange={(event) => {
                    setGurudwaraVisited(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="validationCustom02" className="form-label ">
                  Name of Contact Person at Gurudwara
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={GcontactPerson}
                  onChange={(event) => {
                    setGcontactPerson(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="validationCustom02" className="form-label ">
                  Phone no. of contact person
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={GcontactNumber}
                  onChange={(event) => {
                    setGcontactNumber(event.target.value);
                  }}
                />
              </div>

              <div className="h4 pb-2 mb-2 text-primary border-bottom border-primary">
                Any Other Information
              </div>

              <div className="col-md-12">
                <textarea
                  class="form-control"
                  value={remarks}
                  onChange={(event) => {
                    setRemarks(event.target.value);
                  }}
                ></textarea>
              </div>

              <div class="container text-primary border-bottom border-primary mt-3">
                <div class="row">
                  <div class="col-5 h4 d-flex align-items-center">
                    <p class="mb-0">Reference details:</p>
                  </div>
                  <div class="col text-right d-flex align-items-center">
                    <i class="bi bi-exclamation-triangle-fill text-warning mx-3"></i>
                    <p class="mb-0">
                      Kindly note: Your profile will be published only after the
                      reference person confirms.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <label htmlFor="validationCustom02" className="form-label">
                  Reference Person Name
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={referenceName}
                  onChange={(event) => {
                    setReferenceName(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="validationCustom02" className="form-label">
                  Reference WhatsApp Number
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={referenceNumber}
                  onChange={(event) => {
                    setReferenceNumber(event.target.value);
                  }}
                />
              </div>
            </div>
            <Link
              to="/EditApplicationForm"
              class="btn btn-primary col-auto mt-4"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Save Form And Edit Photos
            </Link>
          </div>

          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5 text-primary" id="exampleModalLabel">
                    GSM says
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <i class="bi bi-exclamation-triangle-fill text-warning mx-2"></i>
                  <span class="text-primary">Are you sure</span>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-dismiss="modal"
                    onClick={saveapplicant}
                  >
                    OK
                  </button>
                  {/* <Link to="/" type="button" class="btn btn-primary" onClick={saveapplicant}>Yes</Link> */}
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default FindAMatch;
