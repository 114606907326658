import axios from "axios";
import React, { useRef } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

function NoBot() {
  const history = useHistory();
  const captchaRef = useRef(null);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaChange = (token) => {
    console.log("token123", token);
    if (token) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("base url", process.env);
    try {
      const tokenreCAPTCHA = captchaRef.current.getValue();
      captchaRef.current.reset();
      console.log("re", tokenreCAPTCHA);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/entry`,
        {
          token: tokenreCAPTCHA,
        }
      );

      if (response.status === 200) {
        history.replace("/dashboard", { verified: true });
      } else {
        alert("Wrong reCAPTCHA");
      }
    } catch (error) {
      // Handle login error
      alert(error.response.data.message);
      console.error(error);
    }
  };
  return (
    <div id="loginmargin" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", flexDirection: "column", }} >Click on I'm Not Robot and click Go to Home Page
      <br />
      <form className="mt-2">
        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY_NEW} ref={captchaRef} onChange={handleCaptchaChange} />
        <button type="submit" className="btn btn-primary" onClick={handleSubmit} style={{ margin: "auto", display: "flex", marginTop: "10px" }} disabled={!captchaVerified} >Go To Home Page</button>
      </form>
    </div>
  );
}

export default NoBot;
