import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import image from "../Photos/image.png";
import heading from "../Photos/heading.png";
import "./AppButton.css";

function ApplicantButton() {
  const location = useLocation();
  const history = useHistory();
  const verified = location.state && location.state.verified;
  if (!verified) {
    history.replace("/");
  }
  console.log("state", verified);

  return (
    <>
      <div
        className="container-fluid"
        style={{ display: "flex", flexDirection: "row", height: "100%" }}
      >
        <div className="col-md-5 col-xs-12 p-4">
          <div className="row">
            <div className="col-md-12">
              <img src={heading} alt="" className="img-fluid" />

              <div className="row">
                <div
                  className="col btn btn-primary m-1 align-items-center"
                  style={{ display: "flex" }}
                  onClick={() => {
                    history.push("/ApplicantRegistration");
                  }}
                >
                  <Link
                    className="btn btn-primary fs-4"
                    to="/ApplicantRegistration"
                    style={{ flex: "1" }}
                    id="button"
                  >
                    Fill Application
                  </Link>
                </div>
                <div
                  className="col btn btn-primary m-1 align-items-center"
                  style={{ display: "flex" }}
                  onClick={() => {
                    history.push("/CheckApplicationStatus");
                  }}
                >
                  <Link
                    className="btn btn-primary fs-4"
                    to="/CheckApplicationStatus"
                    style={{ flex: "1" }}
                    id="button"
                  >
                    Application Status
                  </Link>
                </div>
              </div>
              <div className="row">
                <div
                  className="col btn btn-primary m-1 align-items-center"
                  style={{ display: "flex" }}
                  onClick={() => {
                    history.push("/printApplicationform");
                  }}
                >
                  <Link
                    className="btn btn-primary fs-4"
                    to="/printApplicationform"
                    style={{ flex: "1" }}
                    id="button"
                  >
                    Print Application
                  </Link>
                </div>
                <div
                  className="col btn btn-primary m-1 align-items-center"
                  style={{ display: "flex" }}
                  onClick={() => {
                    history.push("/EditApplicationForm");
                  }}
                >
                  <Link
                    className="btn btn-primary fs-4"
                    to="/EditApplicationForm"
                    style={{ flex: "1" }}
                    id="button"
                  >
                    Modify Application
                  </Link>
                </div>
              </div>
              <div className="row">
                <div
                  className="col btn btn-primary m-1 align-items-center"
                  style={{ display: "flex" }}
                  onClick={() => {
                    history.push("/resendapplicantid");
                  }}
                >
                  <Link
                    className="btn btn-primary fs-4"
                    to="/resendapplicantid"
                    style={{ flex: "1" }}
                    id="button"
                  >
                    Request Applicant ID
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5 largeOnly">
            <div className="col-md-12">
              <img src={image} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        <div className="col-md-7 col-sm-12 p-4 ">
          <div className="row mb-3">
            <div className="col-md-12  text-primary mt-5 fs-5">
              <h3>User Instructions:</h3>
              <ul>
                <li>
                  To get married, click on <b>[Fill Application] </b> button.
                </li>
                <li>
                  To be able to fill the application successfully, you need to have
                  <ul>
                    <li>A valid working accessible Whatsapp number.</li>
                    <li>
                      Two photos of the applicant: <br />one Profile Photo<b className="text-danger">(file size
                        &lt; 250KB)</b> & one Full Photo<b className="text-danger">(file size &lt; 250KB)</b>.
                      <br /><b className="text-danger">Photos upload is compulsory.</b>
                    </li>
                    <li>
                      A valid working Whatsapp number of a <b><u>reference person</u></b>.
                    </li>
                  </ul>
                </li>
                <li>
                  Enter your Whatsapp number and get it validated thru an OTP.
                </li>
                {/* <li>
                On successful validation of your Whatsapp number, the portal
                will guide you to the application form.
              </li> */}
                <li>
                  On successful validation of your Whatsapp number, fill the application form.{" "}
                </li>
                <li>
                  {" "}
                  After submitting the application form
                  <br />
                  you receive Applicant-Id on your verified Whatsapp number. <br />
                  you have to upload 2 photos
                </li>
                <li>
                  After photos upload, the reference person is
                  informed & asked for confirmation.
                </li>
                <li>
                  When the reference person replies with a "yes" followed by your
                  applicant-Id,
                  <ul>
                    <li>
                      Your profile(application form) will be published(visible to
                      other candidates).
                    </li>
                    <li>
                      Your Applicant-Id becomes Candidate-Id & you receive your password.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-5 mb-5 smallOnly">
            <div className="col-md-12">
              <img src={image} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <footer className="fs-6 fixed-bottom" style={{color:"white", backgroundColor: '#99999b', padding: '13px', textAlign: 'center' }}>
        Developed by: Harmanjot Singh (Ph: 8866116969)
      </footer>
    </>
  );
}

export default ApplicantButton;
