import React from 'react'
import { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import khanda from '../Photos/khanda.png';
import apiClient from "../../api";
import heading from "../Photos/heading.png";
import pp from '../Photos/Screenshot 2023-07-25 202918.png';


function LookigForGirl() {
    const [users, setUser] = useState([]);
    const [data, setData] = useState([]);
    const [relative1Info, setRelative1Info] = useState([]);

    const [qualificationoffil, setQualificationoffil] = useState("*");
    const [maritalStatusofFil, setMaritalStatusofFil] = useState("*");
    const [occupationofFil, setOccupationofFil] = useState("*");
    const [religionoffil, setReligionoffil] = useState("*");
    const [heightFromFilter, setHeightFromFilter] = useState("*");
    const [heightToFilter, setHeightToFilter] = useState("*");
    const [incomeFromFilter, setIncomeFromFilter] = useState("*");
    const [ageFromFilter, setAgeFromFilter] = useState("*");
    const [ageToFilter, setAgeToFilter] = useState("*");
    const [locationofFil, setLocationofFil] = useState("*");

    const searchCandidate1 = async () => {

        const response = await apiClient.post("https://api-gsm.sikhfoundation.co.in/getsearchgirl", {
            Qualification: qualificationoffil,
            maritalStatusofFil: maritalStatusofFil,
            occupationofFil: occupationofFil,
            religionoffil: religionoffil,
            heightFromFilter: heightFromFilter,
            heightToFilter: heightToFilter,
            incomeFromFilter: incomeFromFilter,
            ageFromFilter: ageFromFilter,
            ageToFilter: ageToFilter,
            locationofFil: locationofFil,

        });
        setUser(response.data);
    }

    useEffect(() => {
        searchCandidate1();
    }, []);

    const candidate_id = localStorage.getItem("candidate_id");
    const candidate_name = localStorage.getItem("candidate_name");

    const [recivedSubmit, setRecivedSubmit] = useState("");
    console.log("recivedSubmit", recivedSubmit);



    const getCandidateImformation = (valueRecivedFromShowFullProfile) => {
        setData([]);
        setRelative1Info([]);
        setRecivedSubmit("");

        Axios.post("https://api-gsm.sikhfoundation.co.in/getcandidateimformation", {
            CandidateID: valueRecivedFromShowFullProfile,
        }).then((response) => {
            setData(response.data);
        });
        Axios.post("https://api-gsm.sikhfoundation.co.in/recommendapplicantrelation1", {
            CandidateID: valueRecivedFromShowFullProfile,
        }).then((response) => {
            setRelative1Info(response.data);
        });
        Axios.post("https://api-gsm.sikhfoundation.co.in/submit", {
            CandidateID: valueRecivedFromShowFullProfile,
            fromIdiinterest: candidate_id,
        }).then((response) => {
            setRecivedSubmit(response.data);
        })
    };

    if (recivedSubmit === "Interested") {
        document.getElementById("Interested").hidden = false;
    }

    if (recivedSubmit === "Invite sent") {
        document.getElementById("InviteSent").hidden = false;
    }

    if (recivedSubmit === "Invite accepted") {
        document.getElementById("InviteSentAndAccepted").hidden = false;
    }

    if (recivedSubmit === "Invite Received") {
        document.getElementById("InviteRecivedAccepted").hidden = false;
    }

    if (recivedSubmit === "You accepted") {
        document.getElementById("InviteRecivedAndAccepted").hidden = false;
    }
    // const [fromIdiinterest, setFromIdiinterest] = useState("");

    const Iinterest = (valueRecivedFromShowFullProfile, WhatsApp_Number, gender) => {
        Axios.post("https://api-gsm.sikhfoundation.co.in/iinterest", {
            CandidateID: valueRecivedFromShowFullProfile,
            fromIdiinterest: candidate_id,
            WhatsApp_Number: WhatsApp_Number,
            candidate_name: candidate_name,
            gender: gender,
        });
    }

    const refreshPage = () => {
        window.location.reload();
    };


    return (
        <>
            <div className="mt-2 text-primary text-center sticky-top" style={{ "backgroundColor": "white" }}>
                <img src={heading} alt="" class="mx-2 col-md-5 text-center img-fluid" />
            </div>
            <h2 className='text-primary text-center my-3'>Look for Bride</h2>
            <nav class="navbar navbar-expand-lg navbar-light bg-light my-2">
                <div class="container-fluid">

                    <button class="btn bg-primary navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="text-white">Filter</span>
                    </button>
                    <div class="col-auto d-lg-none">
                        <a href="#" class='btn btn-primary' onClick={searchCandidate1} data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"
                            aria-expanded="false" aria-label="Close Navigation"><i class="bi bi-search mx-1"></i>Search</a>
                    </div>
                    <Link className='d-lg-none btn btn-primary' to="/match"><i class="bi bi-caret-left-fill"></i> Back</Link>

                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <div class="row g-3 align-items-center hstack mx-2 my-2">
                                    <div class="col-auto">
                                        <label for="inputPassword6" class="col-form-label">Age</label>
                                    </div>
                                    <div class="col-md-1">
                                        <input type="number" placeholder='From' class="form-control" onChange={(event) => { setAgeFromFilter(event.target.value) }} />
                                    </div>
                                    <div class="col-md-1">
                                        <input type="number" placeholder='To' class="form-control" onChange={(event) => { setAgeToFilter(event.target.value) }} />
                                    </div>
                                    <div class="d-flex col-auto">
                                        <span class=" fs-2 border border-2 border-primary d-none d-md-inline" style={{ height: "35px" }}></span>
                                    </div>
                                    <div class="col-auto">
                                        <label for="inputPassword6" class="col-form-label">Height</label>
                                    </div>
                                    <div class="col-md-1">
                                        <input type="number" class="form-control" placeholder="From" onChange={(event) => { setHeightFromFilter(event.target.value) }} />
                                    </div>
                                    <div class="col-md-1">
                                        <input type="number" class="form-control" placeholder="To" onChange={(event) => { setHeightToFilter(event.target.value) }} />
                                    </div>

                                    <div class="d-flex col-auto">
                                        <span class=" fs-2 border border-2 border-primary d-none d-md-inline" style={{ height: "35px" }}></span>
                                    </div>

                                    <div class="col-auto">
                                        <label for="inputPassword6" class="col-form-label">Qualification</label>
                                    </div>
                                    <div class="col-md-1">
                                        <select className="form-select" onChange={(event) => { setQualificationoffil(event.target.value) }}>
                                            <option value="DEFAULT" selected disabled>Select Qualification</option>
                                            <option>Under Graduate</option>
                                            <option>Graduate</option>
                                            <option>Post Graduate</option>
                                        </select>
                                    </div>
                                    <div class="d-flex col-auto">
                                        <span class=" fs-2 border border-2 border-primary d-none d-md-inline" style={{ height: "35px" }}></span>
                                    </div>



                                    <div class="col-auto">
                                        <label for="inputPassword6" class="col-form-label">Marital Status</label>
                                    </div>
                                    <div class="col-md-1">
                                        <select className="form-select" onChange={(event) => { setMaritalStatusofFil(event.target.value) }}>
                                            <option value="DEFAULT" selected disabled>Select Marital Status</option>
                                            <option>Never married</option>
                                            <option>Divorcee</option>
                                            <option>Widow/Widower</option>
                                        </select>
                                    </div>

                                    <div class="d-flex col-auto">
                                        <span class=" fs-2 border border-2 border-primary d-none d-md-inline" style={{ height: "35px" }}></span>
                                    </div>


                                    <div class="col-auto">
                                        <label for="inputPassword6" class="col-form-label">Occupation</label>
                                    </div>
                                    <div class="col-md-1">
                                        <select className="form-select" onChange={(event) => { setOccupationofFil(event.target.value) }}>
                                            <option value="DEFAULT" selected disabled>Select Occupation</option>
                                            <option>Businessman</option>
                                            <option>Professional</option>
                                            <option>Govt. Service</option>
                                            <option>Private Service</option>
                                            <option>Serving Abroad</option>
                                            <option>Other</option>
                                        </select>
                                    </div>


                                    <div class="d-flex col-auto">
                                        <span class=" fs-2 border border-2 border-primary d-none d-md-inline" style={{ height: "35px" }}></span>
                                    </div>


                                    <div class="col-auto">
                                        <label for="inputPassword6" class="col-form-label">Religion</label>
                                    </div>
                                    <div class="col-md-1">
                                        <select className="form-select" onChange={(event) => { setReligionoffil(event.target.value) }}>
                                            <option value="DEFAULT" selected disabled>Select Religion</option>
                                            <option>Sikh (Keshdhari)</option>
                                            <option>Sikh (Amritdhari)</option>
                                            <option>Sikh</option>
                                        </select>
                                    </div>



                                    <div class="d-flex col-auto">
                                        <span class=" fs-2 border border-2 border-primary d-none d-md-inline" style={{ height: "35px" }}></span>
                                    </div>

                                    <div class="col-auto">
                                        <label for="inputPassword6" class="col-form-label">Location</label>
                                    </div>
                                    <div class="col-md-1">
                                        <input type="text" placeholder='' class="form-control" onChange={(event) => { setLocationofFil(event.target.value) }} />
                                    </div>
                                    <div class="d-flex col-auto">
                                        <span class=" fs-2 border border-2 border-primary d-none d-md-inline" style={{ height: "35px" }}></span>
                                    </div>
                                    <div class="col-auto">
                                        <label for="inputPassword6" class="col-form-label">Annual Income</label>
                                    </div>
                                    <div class="col-md-1">
                                        <input type="number" placeholder='' class="form-control" onChange={(event) => { setIncomeFromFilter(event.target.value) }} />
                                    </div>
                                    <div class="d-flex col-auto">
                                        <span class=" fs-2 border border-2 border-primary d-none d-md-inline" style={{ height: "35px" }}></span>
                                    </div>
                                    <div class="col-auto">
                                        <a href="#" class='btn btn-primary' onClick={searchCandidate1} data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"
                                            aria-expanded="false" aria-label="Close Navigation"><i class="bi bi-search mx-1"></i>Search</a>
                                    </div>
                                    <div class="col-auto">
                                        <button className="btn btn-primary" onClick={refreshPage}>Clear Filter</button>
                                    </div>
                                    <div class="col-auto">
                                        <Link className='btn btn-primary d-none d-lg-inline' to="/match"><i class="bi bi-caret-left-fill mx-2"></i> Back</Link>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="container">
                <div className="row">
                    {users.length === 0 ? <h2 className="text-primary text-center">No such candidate</h2> : users.map((val, key) => {
                        return (
                            <>
                                <div className="col-md-3 my-2">
                                    <div className="card">
                                            <img src={`https://api-gsm.sikhfoundation.co.in/uploads1/${val.profilephoto}`} className="img-thumbnail rounded mx-auto d-block" alt="..." style={{ width: "400px", height: "300px" }} />
                                        <div className="card-body">
                                            <p className="card-text text-primary fs-5">{val.candidate_firstname}</p>
                                            {/* <p className="card-text text-primary fs-5">{val.Candidate_id}</p> */}
                                            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalToggle" onClick={() => getCandidateImformation(val.Candidate_id)}>Show Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}

                    <div className="modal fade modal-xl" id="exampleModalToggle" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <button type="button" className="btn-close mt-3 mx-3 ms-auto" data-bs-dismiss="modal" aria-label="Close"></button>
                                <div className="modal-body modal-dialog modal-dialog-scrollable">
                                    {data.map((val, key) => {
                                        return (
                                            <>
                                                <div className='container'>
                                                    <div className='mx-3'>
                                                        <div className='col-md-12 text-center' id='heading1'>
                                                            <div className="h4 text-primary" id='heading'>
                                                                <img src={khanda} alt="" style={{ width: "50px", height: "50px" }} id="image1" /> GLOBAL SIKH MATRIMONY<img src={khanda} alt="" style={{ width: "50px", height: "50px" }} />
                                                            </div>
                                                        </div>
                                                        <div className="h4 pb-2 mb-4 mt-4 text-primary border-bottom border-primary border-3 text-center">Applicant Profile</div>

                                                        <form className="row g-3">
                                                            <div className="" >
                                                                    <img src={`https://api-gsm.sikhfoundation.co.in/uploads1/${val.profilephoto}`} className="img-thumbnail rounded mx-auto d-block" alt="..." style={{ width: "400px"}} />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label className="form-label fs-6">Applicant's First Name</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.candidate_firstname} />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label className="form-label fs-6">Applicant's Middle Name</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.candidate_middlename} />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label className="form-label fs-6">Applicant's Last Name</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.candidate_lastname} />
                                                            </div>



                                                            <div className="col-md-3">
                                                                <label className="form-label fs-6">Date of Birth</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={new Date(val.Date_of_Birth).toLocaleDateString('en-GB')} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label className="form-label fs-6">Marital Status</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.Marital_Status} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="validationCustom04" className="form-label fs-6">Religion</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.Religion} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="validationCustom04" className="form-label fs-6">Gender</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.Gender} />
                                                            </div>




                                                            <div className="col-md-4">
                                                                <label htmlFor="validationCustomUsername" className="form-label fs-6">Height</label>
                                                                <div className="input-group has-validation">
                                                                    <span className="input-group-text" id="inputGroupPrepend">cm</span>
                                                                    <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.height_cm} />
                                                                    <div className="invalid-feedback">
                                                                        Please choose a username.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-2">
                                                                <label htmlFor="validationCustomUsername" className="form-label fs-6">Height</label>
                                                                <div className="input-group has-validation">
                                                                    <span className="input-group-text" id="inputGroupPrepend">Feet</span>
                                                                    <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.height_cm * 0.0328084} />
                                                                    <div className="invalid-feedback">
                                                                        Please choose a username.
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                            <div className="col-md-4">
                                                                <label htmlFor="validationCustomUsername" className="form-label fs-6">Weight</label>
                                                                <div className="input-group has-validation">
                                                                    <span className="input-group-text" id="inputGroupPrepend">Kg</span>
                                                                    <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Weight} />
                                                                    <div className="invalid-feedback">
                                                                        Please choose a username.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="validationCustom01" className="form-label fs-6">Any Disability?</label>
                                                                <input className="form-control fs-5" type="text" placeholder="mental and/or physical" defaultValue={val.Any_Disability} />
                                                            </div>



                                                            <div className="col-md-4">
                                                                <label htmlFor="validationCustom02" className="form-label fs-6">Mother Tongue</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.Mother_Tongue} />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="validationCustom02" className="form-label fs-6">Languages Known</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.Languages_Known} />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label htmlFor="validationCustom02" className="form-label fs-6">Nationality</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.Nationality} />
                                                            </div>





                                                            <div className="col-md-3">
                                                                <label htmlFor="validationCustom01" className="form-label fs-6">Education</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.Qualification} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="validationCustom01" className="form-label fs-6">Highest Degree</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.Degree} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="validationCustom02" className="form-label fs-6">Applicant's Occupation</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.Candidate_Occupation} />
                                                            </div>

                                                            <div className="col-md-3">
                                                                <label htmlFor="validationCustom02" className="form-label fs-6">Nature Of Work</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.Nature_Of_Work} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="validationCustom02" className="form-label fs-6">Currency</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.Currency} />
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="validationCustomUsername" className="form-label fs-6">Applicant's Annual Income</label>
                                                                <input type="number" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Candidate_Annual_Income} />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                    <div className='container'>
                                        <div className='mx-2 mt-3'>
                                            <div className="h4 pb-2 mb-3 text-primary border-bottom border-primary border-3">Family & Relatives</div>



                                            <div className='row text-center mt-4'>
                                                <div className="col-md-2 d-md-block d-none">
                                                    <label htmlFor="validationCustomUsername" className="form-label fs-5">Relation Ship</label>
                                                </div>
                                                <div className="col-md-3 d-md-block d-none">
                                                    <label htmlFor="validationCustomUsername" className="form-label fs-5">Relative's Name</label>
                                                </div>
                                                <div className="col-md-2 d-md-block d-none">
                                                    <label htmlFor="validationCustomUsername" className="form-label fs-5">Age</label>
                                                </div>
                                                <div className="col-md-2 d-md-block d-none">
                                                    <label htmlFor="validationCustomUsername" className="form-label fs-5">Education</label>
                                                </div>
                                                <div className="col-md-3 d-md-block d-none">
                                                    <label htmlFor="validationCustomUsername" className="form-label fs-5">Occupation</label>
                                                </div>
                                                <hr className='d-md-none' />
                                            </div>
                                        </div>
                                    </div>
                                    {relative1Info.map((val, key) => {
                                        return (
                                            <>
                                                <div className='container'>
                                                    <div className=''>
                                                        <form className="row g-3">
                                                            <div className='row g-3'>
                                                                <div className="col-md-2">
                                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relationship} />
                                                                </div>

                                                                <div className="col-md-3">
                                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relative_name} />
                                                                </div>

                                                                <div className="col-md-2">
                                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relative_age} />
                                                                </div>

                                                                <div className="col-md-2">
                                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relative_Education} />
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relative_Degree} />
                                                                </div>

                                                                <hr className='d-md-none' />
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                                    {data.map((val, key) => {
                                        return (
                                            <>
                                                <div className='container'>
                                                    <div className='m-3'>
                                                        <form className="row g-3">

                                                            <div className="col-md-3">
                                                                <label htmlFor="validationCustomUsername" className="form-label fs-6">Family's Annual Income</label>
                                                                <div className="input-group has-validation">
                                                                    <span className="input-group-text" id="inputGroupPrepend">₹</span>
                                                                    <input type="number" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Family_Annual_Income} />
                                                                    <div className="invalid-feedback">
                                                                        Please choose a username.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div className='container'>
                                                    <div className='mx-3'>
                                                        <form className="row g-3">
                                                            <div className="h4 pb-2 my-4 text-primary border-bottom border-primary border-3">Gurudwara Details</div>

                                                            <div className="col-md-12">
                                                                <label htmlFor="validationCustom02" className="form-label fs-6">Gurudwara Normally visited : Name & Address</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.Gurudwara_Normally_visited} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="validationCustom02" className="form-label fs-6">Name of Contact Person at Gurudwara</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.G_Contact_Person} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label htmlFor="validationCustom02" className="form-label fs-6">Phone no. of contact person</label>
                                                                <input className="form-control fs-5" type="text" defaultValue={val.G_Phone_Number} />
                                                            </div>

                                                            {val.additional_information.length == 0 ?
                                                                <div></div>
                                                                :
                                                                <div>
                                                                    <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary">Any Other Information</div>
                                                                    <label htmlFor="validationCustom02" className="form-label fs-6">Other information</label>

                                                                    <div className="col-md-12">
                                                                        <p class="form-control" >{val.additional_information}</p>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </form>
                                                    </div>
                                                </div>

                                            </>
                                        );
                                    })}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <div className="col-auto" id='input'>
                                        <input className="form-control" id='input' type="number" value={candidate_id} disabled placeholder='Enter From Candidate Id' hidden />
                                    </div>
                                    {data.map((val, key) => {
                                        return (<>
                                            {/* <button class="btn btn-primary" id='submitbutton' onClick={() => submit(val.Candidate_id)}>Submit</button> */}
                                            <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal" id='Interested' hidden>I'm interested</button>
                                            <button class="btn btn-primary" id='InviteSent' hidden disabled>Invite Sent</button>
                                            <button class="btn btn-primary" id='InviteSentAndAccepted' hidden disabled>Invite Sent And Accepted</button>
                                            <button class="btn btn-primary" id='InviteRecivedAccepted' hidden disabled>Invite Received, Accept?</button>
                                            <button class="btn btn-primary" id='InviteRecivedAndAccepted' hidden disabled>Invite Recived And Accepted</button>
                                        </>)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {data.map((val, key) => {
                        return (<>
                            <div class="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">GLOBAL SIKH MATRIMONY</h1>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <i class="bi bi-exclamation-triangle-fill text-warning" style={{ "marginRight": "8px" }}></i>
                                            {val.Gender === "Male" ? "Mr" : "Ms"} {val.candidate_firstname} will be informed about your interest in {val.Gender === "Male" ? "him" : "her"}. {val.Gender === "Male" ? "He" : "She"} will be able to see your full profile. Do you agree?
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-secondary" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">No</button>
                                            <Link to="/Lookingforgirl" className="btn btn-primary" onClick={() => Iinterest(val.Candidate_id, val.WhatsApp_Number, val.Gender)} data-bs-dismiss="modal">Yes</Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)
                    })}
                </div>
            </div>
        </>
    )
}

export default LookigForGirl
