import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import apiClient from "../../api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import heading from "../Photos/heading.png";

function Example() {
  const location = useLocation();
  const history = useHistory();
  console.log("location", location);
  const {
    CandidateID,
    phoneNo,
    CandidateFullName,
    candidatelastName,
    candidateSurname,
    referenceName,
    referenceNumber,
    Gender,
    isEdit,
  } = location.state;

  const [uploadData, setUploadData] = useState({
    profilephoto: "",
    fullphoto: "",
  });
  const [getUploadCount, setGetUploadCount] = useState({
    profilephoto: 0,
    fullphoto: 0,
  });

  const profileRef = useRef();
  const fullRef = useRef();

  const formhandler = async (e) => {
    let err = null;
    e.preventDefault();
    const formData = new FormData();

    uploadData.profilephoto &&
      formData.append(
        "profilephoto",
        uploadData.profilephoto,
        uploadData.profilephoto.name
      );
    uploadData.fullphoto &&
      formData.append(
        "fullphoto",
        uploadData.fullphoto,
        uploadData.fullphoto.name
      );

    if (!isEdit) {
      apiClient
        .post("https://api-gsm.sikhfoundation.co.in/sendingreference", {
          CandidateNumber: phoneNo,
          CandidateID: CandidateID,
          CandidateFullName: CandidateFullName,
          candidatelastName: candidatelastName,
          candidateSurname: candidateSurname,
          referenceName: referenceName,
          referenceNumber: referenceNumber,
          Gender: Gender,
        })
        .catch((error) => {
          if (error?.response.status === 400) {
            alert(error.response.data.message);
          }
          err = error;
        });
    } else {
      apiClient
        .post("https://api-gsm.sikhfoundation.co.in/sendingreferencefromedit", {
          CandidateNumber: phoneNo,
          CandidateID: CandidateID,
          CandidateFullName: CandidateFullName,
          candidatelastName: candidatelastName,
          candidateSurname: candidateSurname,
          referenceName: referenceName,
          referenceNumber: referenceNumber,
          Gender: Gender,
        })
        .catch((error) => {
          if (error?.response.status === 400) {
            alert(error.response.data.message);
          }
          err = error;
        });
    }
    try {
      await apiClient
        .post(`/uploadDataForm?candidateId=${CandidateID}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          apiClient
            .get(`/getUploadCount2?candidateId=${CandidateID}`)
            .then((res) => {
              setGetUploadCount(res.data.data);
            });
        });
    } catch (error) {
      if (error?.response.status === 400) {
        alert(error.response.data.message);
      }
      err = error;
    }
    if (!err) {
      history.push("/dashboard");
    }
  };
  const profileHandler = (event) => {
    console.log("event", event.target.files);
    setUploadData((prev) => ({ ...prev, profilephoto: event.target.files[0] }));
  };
  const fullPhotoHandler = (event) => {
    console.log("event", event.target.files);
    setUploadData((prev) => ({ ...prev, fullphoto: event.target.files[0] }));
  };

  const removeSelectedProfile = () => {
    profileRef.current.value = "";
    setUploadData((prev) => ({ ...prev, profilephoto: "" }));
  };
  const removeSelectedFullPhoto = () => {
    fullRef.current.value = "";
    setUploadData((prev) => ({ ...prev, fullphoto: "" }));
  };

  useEffect(() => {
    apiClient.get(`/getUploadCount2?candidateId=${CandidateID}`).then((res) => {
      setGetUploadCount(res.data.data);
    });
  }, []);

  useEffect(() => {
    return () => {
      if (uploadData.profilephoto) {
        URL.revokeObjectURL(uploadData.profilephoto);
      }
      if (uploadData.fullphoto) {
        URL.revokeObjectURL(uploadData.fullphoto);
      }
    };
  }, []);

  return (
    <div className="container">
      <form action="" onSubmit={formhandler} encType="multipart/form-data">
        <div className="p-3 text-primary">
          <img src={heading} alt="" className="text-center img-fluid" />
        </div>
        <h2 className="text-primary mx-2 text-center col-md-4">Upload Photos</h2>
        <p className="text-danger text-bold mx-2 fs-3">Photos upload is compulsory. <br /> Your profile won't be published without photos.</p>
        <div className="row">
          <div className="col-md-4 mx-2">
              <label className="text-primary fs-5">Profile Photo</label>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                onChange={profileHandler}
                style={{ display: "inline-block" }}
                ref={profileRef}
                required
              />{" "}
              {uploadData.profilephoto && (
                <div>
                  <img
                    className="my-3"

                    src={URL.createObjectURL(uploadData.profilephoto)}
                    alt="Thumb"
                    width="200px"
                    height="200px"
                  />

                  <button
                    type="button"
                    class="form-control btn btn-primary"
                    onClick={() => removeSelectedProfile()}
                  >
                    Remove this Image
                  </button>
                </div>
              )}
              <div class="fraction">
                <span class="numerator">
                  {getUploadCount.profilephoto + " / " + 10}
                </span>
              </div>
          </div>
          <div className="d-flex col-auto">
            <span className="fs-2 border border-2 border-primary d-none d-md-inline"></span>
          </div>
          <div class="col-sm-4 mx-2">
            <label className="text-primary fs-5">Full Photo</label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              onChange={fullPhotoHandler}
              ref={fullRef}
              required
            />

            {uploadData.fullphoto && (
              <div>
                <img
                  className="my-3"
                  src={URL.createObjectURL(uploadData.fullphoto)}
                  alt="Thumb"
                  width="200px"
                  height="200px"
                />

                <button
                  type="button"
                  class="form-control btn btn-primary"
                  onClick={() => removeSelectedFullPhoto()}
                >
                  Remove this Image
                </button>
              </div>
            )}
            <div class="fraction">
              <span class="numerator">{getUploadCount.fullphoto + " / " + 10}</span>
            </div>
          </div>
        </div>
        <div className="col-md-4 text-center mt-2">
          <button type="submit" className="btn btn-primary mx-1 fs-5 col-5">
            Upload & Save
          </button>
          <Link className="btn btn-primary mx-1 fs-5 col-5" to="/dashboard">
            Back
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Example;
