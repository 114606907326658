import { Link } from "react-router-dom";
// import Axios from "axios";
import { useState } from "react";
import apiClient from "../../api";
import heading from "../Photos/heading.png";


function DisableMyProfile() {
  const [candidateid, setCandidateId] = useState("");
  const [password, setPassword] = useState("");
  // const [showModal, setShowModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [disableButtonVisible, setDisableButtonVisible] = useState(false);
  const [disablecandidatedata, setdisablecandidatedata] = useState([]);
  const [disablecandidatedata1, setdisablecandidatedata1] = useState(false);
  const [whyToDisable, setWhyToDisable] = useState("")
  const candidateName = localStorage.getItem("candidate_name")



  const candidate_id = localStorage.getItem("candidate_id");

  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const checkId = () => {
    apiClient.post("https://api-gsm.sikhfoundation.co.in/disablemyprofile", {
      candidateid: candidate_id,
      password: password,
    }).then((response) => {
      if (response.data === true) {
        setModalMessage("Are you sure");
        setShowModal(true);
        setDisableButtonVisible(true);
        // setdisablecandidatedata(result);
        setdisablecandidatedata1(true);
      } else {
        setModalMessage("password is wrong");
        setShowModal(true);
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const onclickdisable = () => {
    setDisableButtonVisible(false);
    apiClient.post("/disablecandidate1", {
      candidate_id: candidate_id,
      whyToDisable: whyToDisable,
      candidateName: candidateName,
    });
    setModalMessage("Disabled successfully");
    setShowModal(true);
  };

  // const closeModal = () => {
  //   setShowModal(false);
  // };

  return (
    <>
      <div className="mt-2 mx-4 text-primary" >
        <img src={heading} alt="" class="col-md-3 text-center img-fluid" />
      </div>
      <div className="d-grid gap-2 col-md-3 my-3 mx-3">
        <input type="text" className="form-control" value={candidate_id} disabled />
        <input type="password" className="form-control" placeholder="Password" onChange={(event) => { setPassword(event.target.value); }} />
        <input type="text" className="form-control" placeholder="Why to disable" onChange={(event) => { setWhyToDisable(event.target.value); }} />
        <div className="text-center">
          <Link className="btn btn-primary mx-1 fs-5 col-5" to="/Disablemyprofile" onClick={checkId} >Disable</Link>
          <Link className="btn btn-primary mx-1 fs-5 col-5" to="/match">Back</Link>
        </div>
      </div>

      {showModal && (
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-primary">GSM says</h5>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)} ></button>
              </div>
              <div className="modal-body">
                <i class="bi bi-exclamation-triangle-fill text-warning mx-2"></i><span class="text-primary">{modalMessage}</span>
              </div>
              <div className="modal-footer">
                {disableButtonVisible && (
                  <button type="button" className="btn btn-primary" id="disablebutton" onClick={onclickdisable}>Disable</button>
                )}
                {/* <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button> */}
                <Link to="/match" className="btn btn-secondary">Close</Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {response ? (
        <div className={`modal fade ${showModal ? "show" : ""}`} id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden={!showModal} style={{ display: showModal ? "block" : "none" }} >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5 text-primary" id="exampleModalLabel">Candidate Id: {candidateid}</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal} ></button>
              </div>
              <div className="modal-body text-danger">
                <i className="bi bi-exclamation-triangle-fill text-danger mx-3"></i>Are you sure to disable this candidate.</div>
              <div className="modal-footer">
                <Link to="/ApproverButton" type="button" className="btn btn-primary" onClick={sendValue}>Disable</Link>
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal} >Close</button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`modal fade ${showModal ? "show" : ""}`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden={!showModal} style={{ display: showModal ? "block" : "none" }} >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5 text-primary" id="exampleModalLabel">Candidate Id: {candidateid}</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal} ></button>
              </div>
              <div className="modal-body text-primary"><i class="bi bi-exclamation-triangle-fill text-warning"></i>Worng Password</div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal} >Close</button>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}

export default DisableMyProfile;

// import { useState } from "react";
// import Axios from "axios";
// import { Link } from "react-router-dom";



// function DisableMyProfile() {
//     const [variableCandidateID, setterCandidateID] = useState("");

//     const approveapplicant = () => {
//         Axios.post("https://api-gsm.sikhfoundation.co.in/disablemyprofile", {
//             sendingCandidateID: variableCandidateID,
//         })
//     };

//     return (
//         <>
//             <div className="d-grid gap-2 col-md-3 my-3 mx-3">
//                 <input type="text" className="form-control" placeholder="Candidate Id" onChange={(event) => { setterCandidateID(event.target.value) }} />
//                 <input type="text" className="form-control" placeholder="Password" />
//                 <div className="text-center">
//                     <Link className="btn btn-primary mx-1 fs-5 col-5" to="/match" data-bs-toggle="modal" data-bs-target="#exampleModal">Disable</Link>
//                     <Link className="btn btn-primary mx-1 fs-5 col-5" to="/match">Back</Link>
//                 </div>
//             </div>

//             <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//                 <div class="modal-dialog">
//                     <div class="modal-content">
//                         <div class="modal-header">
//                             <h1 class="modal-title fs-5" id="exampleModalLabel">GSM says</h1>
//                             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                         </div>
//                         <div class="modal-body text-danger">
//                         <i class="bi bi-exclamation-triangle-fill"></i> Are you sure.
//                         </div>
//                         <div class="modal-footer">
//                             <button type="button" class="btn btn-primary" onClick={approveapplicant} >Disable</button>
//                             <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// }

// export default DisableMyProfile;