import { Link } from "react-router-dom";
import { useState } from "react";
import apiClient from "../../api";
import heading from "../Photos/heading.png";

function ResetApproverPassword() {

    const candidateId = localStorage.getItem("candidate_id");
    const whatsappNumber = localStorage.getItem("whatsapp_number");
    // console.log("candidate_id", candidateId);

    // const oldpassword1 = "123456";

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    // console.log(typeof newPassword.length);

    const [modalMessage, setModalMessage] = useState("");
    const [showModal, setShowModal] = useState(false);

    const savePassword = () => {
        // if (newPassword.length == 6) {
        if (newPassword === confirmPassword) {
            apiClient.post("https://api-gsm.sikhfoundation.co.in/changepasswordofapprover", {
                candidateId: candidateId,
                oldPassword: oldPassword,
                newPassword: newPassword,
                whatsappNumber: whatsappNumber,
            }).then((response) => {
                if (response.data === true) {
                    // alert("Password changed successfully");
                    setModalMessage("Password changed successfully");
                    setShowModal(true);
                } else {
                    // alert("Old password is wrong");
                    setModalMessage("Old password is wrong");
                    setShowModal(true);
                }
            }).catch((error) => {
                console.log(error);
            });
        }
        else {
            // alert("Check new password and confirm password");
            setModalMessage("Check new password and confirm password");
            setShowModal(true);
        }
        // } else {
        //     alert("New password should be 6 digits long");
        // }
    };


    return (
        <>
            <div className="mt-2 mx-4 text-primary " >
                <img src={heading} alt="" class="col-md-3 text-center img-fluid" />
            </div>
            <div className="d-grid gap-2 col-md-3 my-3 mx-3">
                <input type="number" className="form-control" value={candidateId} disabled />
                <input type="password" className="form-control" placeholder="Old Password" onChange={(event) => { setOldPassword(event.target.value); }} />
                <input type="password" className="form-control" placeholder="New Password" onChange={(event) => { setNewPassword(event.target.value); }} />
                <input type="password" className="form-control" placeholder="Confirm Password" onChange={(event) => { setConfirmPassword(event.target.value); }} />
                <div className="text-center">
                    <Link className="btn btn-primary mx-1 fs-5 col-5" to="/ResetApproverPassword" onClick={savePassword}>Save</Link>
                    <Link className="btn btn-primary mx-1 fs-5 col-5" to="/ApproverButton">Back</Link>
                </div>
            </div>
            {showModal && (
                <div className="modal fade show" style={{ display: "block" }} >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">GSM says</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setShowModal(false)}
                                ></button>
                            </div>
                            <div className="modal-body">{modalMessage}</div>
                            <div className="modal-footer">
                                {/* <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => setShowModal(false)}
                                    autoFocus
                                >
                                    Close
                                </button> */}
                                <Link className="btn btn-secondary" to="/ApproverButton">Ok</Link>

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ResetApproverPassword;