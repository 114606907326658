import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import apiClient from '../../api';
import heading from "../Photos/heading.png";


function ResetPassword() {
  const candidateId = localStorage.getItem('candidate_id');
  const whatsappNumber = localStorage.getItem('whatsapp_number');

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const savePassword = () => {
    if (newPassword === confirmPassword) {
      apiClient
        .post('https://api-gsm.sikhfoundation.co.in/changepasswordofcandidate', {
          candidateId: candidateId,
          oldPassword: oldPassword,
          newPassword: newPassword,
          whatsappNumber: whatsappNumber,
        })
        .then((response) => {
          if (response.data === true) {
            setModalMessage('Password changed successfully');
            setShowModal(true);
          } else {
            setModalMessage('Old password is wrong');
            setShowModal(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setModalMessage('Check new password and confirm password');
      setShowModal(true);
    }
  };

  return (
    <>
      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">GSM says</h5>
                <button type="button" className="btn-close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body">{modalMessage}</div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-primary" onClick={() => setShowModal(false)} autoFocus>
                  Close
                </button> */}
                <Link to="/match" className="btn btn-secondary">Close</Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-2 mx-4 text-primary" >
        <img src={heading} alt="" class="col-md-3 text-center img-fluid" />
      </div>
      <form className="col-md-3 my-3 mx-3">
        <input type="text" className="form-control my-2" placeholder="Candidate-ID" value={candidateId} disabled />
        <input
          type={showPassword ? 'text' : 'password'}
          className="form-control my-2"
          placeholder="Old Password"
          value={oldPassword}
          onChange={(event) => setOldPassword(event.target.value)}
        />
        <input
          type={showPassword ? 'text' : 'password'}
          className="form-control my-2"
          placeholder="New Password"
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />
        <input
          type={showPassword ? 'text' : 'password'}
          className="form-control"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
        />
        <div className="form-check my-2">
          <input className="form-check-input" type="checkbox" id="showPassword" checked={showPassword} onChange={togglePasswordVisibility} />
          <label className="form-check-label" htmlFor="showPassword">
            Show Password
          </label>
        </div>
        <Link className="col-md-3 btn btn-primary m-2" to="/ResetPassword" onClick={savePassword}>
          Save
        </Link>
        <Link to="/match" className="col-md-3 btn btn-primary m-2">
          Back
        </Link>
      </form>
    </>
  );
}

export default ResetPassword;
