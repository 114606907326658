import React, { useRef } from "react";
import { useState } from "react";
import Axios from "axios";
// import { Link } from "react-router-dom";
import { Link, useHistory, useLocation } from "react-router-dom";

import image from "../Photos/Gurudwaras-in-India.jpg";
// import ReCAPTCHA from "react-google-recaptcha"
import heading from "../Photos/heading.png";
import apiClient from "../../api";

function CheckApplicationStatus() {

  const location = useLocation();
  const history = useHistory();

  const [applicationStatus, setApplicationStatus] = useState([]);
  const [candidateID, setcandidateID] = useState([]);

  const getApplicationStatus = () => {
    apiClient.post("/applicationstatus", {
      candidateID1: candidateID,
    }).then((response) => {
      setApplicationStatus(response.data);
    });
  };

  const gotodashboard = () => {
    history.push("/dashboard", { verified: true });
  }


  return (
    <>
      <div className="mx-3">
        <form>
          <div className=" p-3 text-primary" >
            <img src={heading} alt="" class="col-md-5 text-center img-fluid" />
          </div>
          <div className="col-md-5">
            <div className="">
              <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary border-3 mx-4 text-center" id="title" >Check Application Status</div>
              <div className="my-3 col-md-7 mx-4">
                <label className="form-label">Applicant Id</label>
                <input type="number" className="form-control" id="exampleInputEmail1" onChange={(event) => { setcandidateID(event.target.value); }} autoFocus autoComplete="off" />
              </div>

              <button type="button" style={{ marginLeft: "23px" }} className="btn btn-primary" onClick={getApplicationStatus} data-bs-toggle="modal" data-bs-target="#exampleModal" >Check</button>

              <Link onClick={gotodashboard} className="btn btn-primary mx-1">Back</Link>
              <Link className="btn btn-danger" to="/resendapplicantid">Forgot applicant-ID</Link>

            </div>
          </div>
        </form>
      </div>


      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5 text-primary" id="exampleModalLabel">GSM says</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {applicationStatus.length === 0 ? <div><b class="text-primary"><i class="bi bi-exclamation-triangle-fill text-warning mx-2"></i>Applicant ID incorrect</b></div> :

                applicationStatus.map((val, key) => {
                  if (val.Status === "Approved") {
                    return (
                      <div>
                        <b class="text-primary">Application of {val.candidate_firstname} has been Approved. Candidate ID is {candidateID}.</b>
                        <br />
                        <Link to="/match" className="btn btn-primary my-2" target="_parent" >Find A Match</Link>
                      </div>
                    );
                  }


                  else if (val.Status === "Under Process" || val.Status === "Recommended") {
                    return (
                      <div>
                        <b class="text-primary">Your application is waiting for approval</b>
                      </div>
                    );
                  }

                  else if (val.Status === "In Complete") {
                    return (
                      <div>
                        <b class="text-primary">Your photos upload is pending</b>
                        <br />
                        <Link to="/EditApplicationForm" className="btn btn-primary my-2" target="_parent" >Modify Application</Link>
                      </div>
                    );
                  }

                  else if (val.Status === "Disabled") {
                    return (
                      <div>
                        <b class="text-primary">Your Application is Disabled</b>
                      </div>
                    );
                  }

                  else if (val.Status === "Enabled") {
                    return (
                      <div>
                        <b class="text-primary">Your Application is Enabled</b>
                      </div>
                    );
                  }
                })}
            </div>
            <div className="modal-footer">
              {applicationStatus.length === 0 ? <div><Link className="btn btn-danger mx-2" to="/resendapplicantid" target="_parent">Request Applicant ID</Link><Link onClick={gotodashboard} className="btn btn-secondary" target="_parent">Ok</Link></div> :
                <Link onClick={gotodashboard} className="btn btn-primary mx-1" target="_parent">Back</Link>
              }

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckApplicationStatus;
