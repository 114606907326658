import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import apiClient from "../../api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import heading from "../Photos/heading.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function UploadFormPhoto() {
  const location = useLocation();
  const history = useHistory();
  const gotodashboard = () => {
    history.push("/dashboard", { verified: true });
  }
  console.log("location", location);
  const {
    CandidateID,
    phoneNo,
    CandidateFullName,
    candidatelastName,
    candidateSurname,
    referenceName,
    referenceNumber,
    Gender,
    isEdit,
    countofsendingreference,
  } = location.state;

  const [uploadData, setUploadData] = useState({
    profilephoto: "",
    fullphoto: "",
  });
  const [getUploadCount, setGetUploadCount] = useState({
    profilephoto: 0,
    fullphoto: 0,
  });

  const profileRef = useRef();
  const fullRef = useRef();

  const formhandler = async (e) => {
    let err = null;
    e.preventDefault();
    const formData = new FormData();

    uploadData.profilephoto &&
      formData.append(
        "profilephoto",
        uploadData.profilephoto,
        uploadData.profilephoto.name
      );
    uploadData.fullphoto &&
      formData.append(
        "fullphoto",
        uploadData.fullphoto,
        uploadData.fullphoto.name
      );

    if (!isEdit) {
      apiClient
        .post("https://api-gsm.sikhfoundation.co.in/sendingreference", {
          CandidateNumber: phoneNo,
          CandidateID: CandidateID,
          CandidateFullName: CandidateFullName,
          candidatelastName: candidatelastName,
          candidateSurname: candidateSurname,
          referenceName: referenceName,
          referenceNumber: referenceNumber,
          Gender: Gender,
        })
        .catch((error) => {
          if (error?.response.status === 400) {
            // alert(error.response.data.message);
            setShow(true);
            setModalMessage(
              error.response.data.message
            );
          }
          err = error;
        });
    } else {
      apiClient
        .post("https://api-gsm.sikhfoundation.co.in/sendingreferencefromedit", {
          CandidateNumber: phoneNo,
          CandidateID: CandidateID,
          CandidateFullName: CandidateFullName,
          candidatelastName: candidatelastName,
          candidateSurname: candidateSurname,
          referenceName: referenceName,
          referenceNumber: referenceNumber,
          Gender: Gender,
          countofsendingreference: countofsendingreference,
        })
        .catch((error) => {
          if (error?.response.status === 400) {
            // alert(error.response.data.message);
            setShow(true);
            setModalMessage(
              error.response.data.message
            );
          }
          err = error;
        });
    }
    try {
      await apiClient
        .post(`/uploadDataForm?candidateId=${CandidateID}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          apiClient
            .get(`/getUploadCount2?candidateId=${CandidateID}`)
            .then((res) => {
              setGetUploadCount(res.data.data);
            });
        });
    } catch (error) {
      if (error?.response.status === 400) {
        // alert(error.response.data.message);
        setShow(true);
        setModalMessage(
          error.response.data.message
        );
      }
      err = error;
    }
    // if (!err) {
    //   history.push("/dashboard");
    // }
  };
  const profileHandler = (event) => {
    console.log("event", event.target.files);
    setUploadData((prev) => ({ ...prev, profilephoto: event.target.files[0] }));
  };
  const fullPhotoHandler = (event) => {
    console.log("event", event.target.files);
    setUploadData((prev) => ({ ...prev, fullphoto: event.target.files[0] }));
  };

  const removeSelectedProfile = () => {
    profileRef.current.value = "";
    setUploadData((prev) => ({ ...prev, profilephoto: "" }));
  };
  const removeSelectedFullPhoto = () => {
    fullRef.current.value = "";
    setUploadData((prev) => ({ ...prev, fullphoto: "" }));
  };

  useEffect(() => {
    apiClient.get(`/getUploadCount2?candidateId=${CandidateID}`).then((res) => {
      setGetUploadCount(res.data.data);
    });
  }, []);

  const [modalMessage, setModalMessage] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);


  useEffect(() => {
    return () => {
      if (uploadData.profilephoto) {
        URL.revokeObjectURL(uploadData.profilephoto);
      }
      if (uploadData.fullphoto) {
        URL.revokeObjectURL(uploadData.fullphoto);
      }
    };
  }, []);

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} >
        <Modal.Header>
          <Modal.Title className="text-primary">GSM says</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-primary">{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose} autoFocus>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <form action="" onSubmit={formhandler} encType="multipart/form-data">
        <div className="p-3 text-primary col-md-12 text-center" >
          <img src={heading} alt="" class="col-md-4 text-center img-fluid" />
        </div>
        <h2 className="text-primary text-center col-md-12">Upload Photos</h2>
        <p className="text-danger text-center text-bold mx-2 fs-3">Photos upload is compulsory. <br /> Your profile won't be published without photos.</p>
        {/* <div className="d-grid gap-2 col-md-3 my-4 mx-3">
          <label className="text-primary fs-5">Profile Photo</label>
          <input
            className="form-control"
            type="file"
            accept="image/*"
            onChange={profileHandler}
            style={{ display: "inline-block" }}
            ref={profileRef}
            required
          />{" "}
          {uploadData.profilephoto && (
            <div>
              <img
                src={URL.createObjectURL(uploadData.profilephoto)}
                alt="Thumb"
                width="200px"
                height="200px"
              />

              <button
                type="button"
                class="form-control btn btn-primary"
                onClick={() => removeSelectedProfile()}
              >
                Remove this Image
              </button>
            </div>
          )}
          <div class="fraction">
            <span class="numerator">
              {getUploadCount.profilephoto + " / " + 10}
            </span>
          </div>
          <label className="text-primary fs-5">Full Photo</label>
          <input
            className="form-control"
            type="file"
            accept="image/*"
            onChange={fullPhotoHandler}
            ref={fullRef}
            required
          />
          <span class="numerator">{getUploadCount.fullphoto + " / " + 10}</span>
          {uploadData.fullphoto && (
            <div>
              <img
                src={URL.createObjectURL(uploadData.fullphoto)}
                alt="Thumb"
                width="200px"
                height="200px"
              />

              <button
                type="button"
                class="form-control btn btn-primary"
                onClick={() => removeSelectedFullPhoto()}
              >
                Remove this Image
              </button>
            </div>
          )}
          <div className="text-center mt-2">
            <button type="submit" class="btn btn-primary mx-1 fs-5 col-5">
              Upload & Save
            </button>

            <Link className="btn btn-primary mx-1 fs-5 col-5" to="/dashboard">
              Back
            </Link>
          </div>
        </div> */}
        <div class="row justify-content-center mx-2">
          <div class="col-md-5 mx-2">
            <label className="text-primary fs-5">Profile Photo</label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              onChange={profileHandler}
              style={{ display: "inline-block" }}
              ref={profileRef}
              required
            />{" "}
            {uploadData.profilephoto && (
              <div>
                <div className="text-center">
                  <img
                    className="my-3"

                    src={URL.createObjectURL(uploadData.profilephoto)}
                    alt="Thumb"
                    width="200px"
                    height="200px"
                  />
                </div>

                <button
                  type="button"
                  class="form-control btn btn-primary"
                  onClick={() => removeSelectedProfile()}
                >
                  Remove this Image
                </button>
              </div>
            )}
            {/* <div class="fraction">
              <span class="numerator">
                {getUploadCount.profilephoto + " / " + 10}
              </span>
            </div> */}
          </div>
          <div class="d-flex col-auto">
            <span class=" fs-2 border border-2 border-primary d-none d-md-inline"></span>
          </div>
          <div class="col-sm-5 mx-2">
            <label className="text-primary fs-5">Full Photo</label>
            <input
              className="form-control"
              type="file"
              accept="image/*"
              onChange={fullPhotoHandler}
              ref={fullRef}
              required
            />

            {uploadData.fullphoto && (
              <div>
                <div className="text-center">
                  <img
                    className="my-3"
                    src={URL.createObjectURL(uploadData.fullphoto)}
                    alt="Thumb"
                    width="200px"
                    height="200px"
                  />
                </div>

                <button
                  type="button"
                  class="form-control btn btn-primary"
                  onClick={() => removeSelectedFullPhoto()}
                >
                  Remove this Image
                </button>
              </div>
            )}
            {/* <div class="fraction">
              <span class="numerator">{getUploadCount.fullphoto + " / " + 10}</span>
            </div> */}
          </div>
        </div>
        {/* <div className="mx-5 mt-5">
            <button type="submit" class="btn btn-primary fs-5 col-2">
              Upload & Save
            </button>

            <Link className="btn btn-primary mx-4 fs-5 col-2" to="/dashboard">
              Back
            </Link>
          </div> */}
        <div className="col-md-12 justify-content-center text-center mt-4 mb-5">
          <button type="submit" className="btn btn-primary mx-1 fs-5 col-md-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Upload & Save
          </button>
          {/* <Link className="btn btn-primary mx-1 fs-5 col-md-2" to="/dashboard">
            Back
          </Link> */}
        </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5 text-primary" id="exampleModalLabel">GSM Says</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <b className="text-primary">Your application has been saved. It will be visible to other candidates after the approval of the reference person.</b>
              </div>
              <div class="modal-footer">
                <Link onClick={gotodashboard} class="btn btn-secondary" target="_parent">Ok</Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default UploadFormPhoto;
