import React, { useState } from 'react'
import Axios from "axios";
import { Link } from "react-router-dom";
import khanda from '../Photos/khanda.png';
import { useEffect } from 'react';
import heading from "../Photos/heading.png";
import pp from '../Photos/Screenshot 2023-07-25 202918.png';
import fp from '../Photos/fp.png';
import ipp from '../Photos/ipp.png';


function IInterestedIn() {

  const [frominput, setFrominput] = useState("");
  const [InterestData, setinterestData] = useState([]);
  const [data, setData] = useState([]);
  const [relative1Info, setRelative1Info] = useState([]);
  const [status, setStatus] = useState([]);
  console.log(status);

  const candidate_id = localStorage.getItem("candidate_id");
  const candidate_name = localStorage.getItem("candidate_name");


  useEffect(() => {
    iinterestin();
  }, []);


  const iinterestin = () => {
    Axios.post("https://api-gsm.sikhfoundation.co.in/iinterestbut", {
      frominput: candidate_id,
    }).then((response) => {
      setinterestData(response.data);
    });
  };

  const getCandidateImformation = (valueRecivedFromShowFullProfile) => {
    setData([]);
    setStatus([]);
    setRelative1Info([]);
    Axios.post("https://api-gsm.sikhfoundation.co.in/getstatus", {
      CandidateID: valueRecivedFromShowFullProfile,
      frominput: candidate_id,
    }).then((response) => {
      setStatus(response.data);
      Axios.post("https://api-gsm.sikhfoundation.co.in/getcandidateimformation", {
        CandidateID: valueRecivedFromShowFullProfile,
      }).then((response) => {
        setData(response.data);
        Axios.post("https://api-gsm.sikhfoundation.co.in/recommendapplicantrelation1", {
          CandidateID: valueRecivedFromShowFullProfile,
        }).then((response) => {
          setRelative1Info(response.data);
        });
      })
    })
  };

  // Axios.post("https://api-gsm.sikhfoundation.co.in/getcandidateimformation", {
  //   CandidateID: valueRecivedFromShowFullProfile,
  // }).then((response) => {
  //   setData(response.data);
  //   Axios.post("https://api-gsm.sikhfoundation.co.in/recommendapplicantrelation1", {
  //     CandidateID: valueRecivedFromShowFullProfile,
  //   }).then((response) => {
  //     setRelative1Info(response.data);
  //   });
  // })


  // Axios.post("https://api-gsm.sikhfoundation.co.in/getcandidateimformation", {
  //       CandidateID: valueRecivedFromShowFullProfile,
  //     }).then((response) => {
  //       setData(response.data);
  //     });
  //     Axios.post("https://api-gsm.sikhfoundation.co.in/recommendapplicantrelation1", {
  //       CandidateID: valueRecivedFromShowFullProfile,
  //     }).then((response) => {
  //       setRelative1Info(response.data);
  //     });



  return (
    <>
      {/* <div className='col-md-3 my-3 mx-3'>
        <input className="form-control" type="text" placeholder='From Candidate Id' onChange={(event) => { setFrominput(event.target.value) }} />
        <button type="button" className="btn btn-primary my-2" onClick={iinterestin} >Show</button>
        <Link to='/match' className='btn btn-primary mx-1'>Home</Link>

      </div> */}

      <div className="container">
        <div className="mt-2 text-primary text-center sticky-top" style={{ "backgroundColor": "white" }}>
          <img src={heading} alt="" class="mx-2 col-md-5 text-center img-fluid" />
        </div>
        <h2 className='text-primary mt-2 text-center '>{candidate_name} is interested in</h2>
        <Link className='mx-3 mt-2 btn btn-primary mb-3' to="/match"><i class="bi bi-caret-left-fill mx-2"></i> Back</Link>
        <div className="row">
          {/* {InterestData.map((val, key) => { */}
          {InterestData.length === 0 ? <h2 className="text-primary text-center">No such candidate</h2> : InterestData.map((val, key) => {
            return (
              <>
                <div className="col-md-3">
                  <div className="card">
                    <img src={`https://api-gsm.sikhfoundation.co.in/uploads1/${val.profilephoto}`} className="img-thumbnail rounded mx-auto d-block" alt="..." style={{ width: "400px", height: "300px" }} />
                    <div className="card-body">
                      <p className="card-text text-primary fs-5">{val.candidate_firstname}</p>
                      {/* <p className="card-text text-primary fs-5" id="ptag" key={val.Candidate_id}>{val.Candidate_id}</p> */}
                      {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={getCandidateImformation}>Show Full Profile</button> */}
                      <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => getCandidateImformation(val.Candidate_id)}>Show Profile</button>
                    </div>
                    <div className="modal fade modal-xl" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <button type="button" className="btn-close mt-3 mx-3 ms-auto" data-bs-dismiss="modal" aria-label="Close"></button>
                          <div className="modal-body modal-dialog modal-dialog-scrollable">
                            {status.map((val, key) => {
                              if (val.Status === "Offered") {
                                return (
                                  <>
                                    {data.map((val, key) => {
                                      return (
                                        <>
                                          <div className='container'>
                                            <div className='mx-3'>
                                              <div className='col-md-12 text-center' id='heading1'>
                                                <div className="h4 text-primary" id='heading'>
                                                  <img src={khanda} alt="" style={{ width: "50px", height: "50px" }} id="image1" /> GLOBAL SIKH MATRIMONY<img src={khanda} alt="" style={{ width: "50px", height: "50px" }} />
                                                </div>
                                              </div>
                                              <div className="h4 pb-2 mb-4 mt-4 text-primary border-bottom border-primary border-3 text-center">Applicant Profile</div>

                                              <form className="row g-3">
                                                <div className="" >
                                                  <img src={`https://api-gsm.sikhfoundation.co.in/uploads1/${val.profilephoto}`} className="img-thumbnail rounded mx-auto d-block" alt="..." style={{ width: "400px" }} />
                                                </div>
                                                <div className="col-md-4">
                                                  <label className="form-label fs-6">Applicant's First Name</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.candidate_firstname} />
                                                </div>
                                                <div className="col-md-4">
                                                  <label className="form-label fs-6">Applicant's Middle Name</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.candidate_middlename} />
                                                </div>
                                                <div className="col-md-4">
                                                  <label className="form-label fs-6">Applicant's Last Name</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.candidate_lastname} />
                                                </div>



                                                <div className="col-md-3">
                                                  <label className="form-label fs-6">Date of Birth</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={new Date(val.Date_of_Birth).toLocaleDateString('en-GB')} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label className="form-label fs-6">Marital Status</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Marital_Status} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom04" className="form-label fs-6">Religion</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Religion} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom04" className="form-label fs-6">Gender</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Gender} />
                                                </div>




                                                <div className="col-md-4">
                                                  <label htmlFor="validationCustomUsername" className="form-label fs-6">Height</label>
                                                  <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">cm</span>
                                                    <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.height_cm} />
                                                    <div className="invalid-feedback">
                                                      Please choose a username.
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-md-4">
                                                  <label htmlFor="validationCustomUsername" className="form-label fs-6">Weight</label>
                                                  <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">Kg</span>
                                                    <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Weight} />
                                                    <div className="invalid-feedback">
                                                      Please choose a username.
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-md-4">
                                                  <label htmlFor="validationCustom01" className="form-label fs-6">Any Disability?</label>
                                                  <input className="form-control fs-5" type="text" placeholder="mental and/or physical" defaultValue={val.Any_Disability} />
                                                </div>



                                                <div className="col-md-4">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Mother Tongue</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Mother_Tongue} />
                                                </div>
                                                <div className="col-md-4">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Languages Known</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Languages_Known} />
                                                </div>
                                                <div className="col-md-4">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Nationality</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Nationality} />
                                                </div>





                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom01" className="form-label fs-6">Education</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Qualification} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom01" className="form-label fs-6">Highest Degree</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Degree} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Applicant's Occupation</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Candidate_Occupation} />
                                                </div>

                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Nature Of Work</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Nature_Of_Work} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Currency</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Currency} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustomUsername" className="form-label fs-6">Applicant's Annual Income</label>
                                                  <input type="number" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Candidate_Annual_Income} />
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                    <div className='container'>
                                      <div className='mx-2 mt-3'>
                                        <div className="h4 pb-2 mb-3 text-primary border-bottom border-primary border-3">Family & Relatives</div>



                                        <div className='row text-center mt-4'>
                                          <div className="col-md-2 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Relation Ship</label>
                                          </div>
                                          <div className="col-md-3 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Relative's Name</label>
                                          </div>
                                          <div className="col-md-2 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Age</label>
                                          </div>
                                          <div className="col-md-2 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Education</label>
                                          </div>
                                          <div className="col-md-3 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Occupation</label>
                                          </div>
                                          <hr className='d-md-none' />
                                        </div>
                                      </div>
                                    </div>
                                    {relative1Info.map((val, key) => {
                                      return (
                                        <>
                                          <div className='container'>
                                            <div className=''>
                                              <form className="row g-3">
                                                <div className='row g-3'>
                                                  <div className="col-md-2">
                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relationship} />
                                                  </div>

                                                  <div className="col-md-3">
                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relative_name} />
                                                  </div>

                                                  <div className="col-md-2">
                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relative_age} />
                                                  </div>

                                                  <div className="col-md-2">
                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relative_Education} />
                                                  </div>
                                                  <div className="col-md-3">
                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relative_Degree} />
                                                  </div>

                                                  <hr className='d-md-none' />
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                    {data.map((val, key) => {
                                      return (
                                        <>
                                          <div className='container'>
                                            <div className='m-3'>
                                              <form className="row g-3">

                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustomUsername" className="form-label fs-6">Family's Annual Income</label>
                                                  <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">₹</span>
                                                    <input type="number" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Family_Annual_Income} />
                                                    <div className="invalid-feedback">
                                                      Please choose a username.
                                                    </div>
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          </div>

                                          <div className='container'>
                                            <div className='mx-3'>
                                              <form className="row g-3">
                                                <div className="h4 pb-2 my-4 text-primary border-bottom border-primary border-3">Gurudwara Details</div>

                                                <div className="col-md-12">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Gurudwara Normally visited : Name & Address</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Gurudwara_Normally_visited} />
                                                </div>
                                                <div className="col-md-6">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Name of Contact Person at Gurudwara</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.G_Contact_Person} />
                                                </div>
                                                <div className="col-md-6">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Phone no. of contact person</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.G_Phone_Number} />
                                                </div>

                                                <div className="h4 pb-2 mb-2 text-primary border-bottom border-primary">Any Other Information</div>

                                                <div className="col-md-12">
                                                  <p class="form-control" >{val.additional_information.length == 0 ? "None" : val.additional_information}</p>
                                                </div>

                                              </form>
                                            </div>
                                          </div>

                                        </>
                                      );
                                    })}
                                  </>
                                );
                              }
                              if (val.Status === "Accepted") {
                                return (
                                  <>
                                    {data.map((val, key) => {
                                      return (
                                        <>
                                          <div className='container'>
                                            <div className='mx-3'>
                                              <div className='col-md-12 text-center' id='heading1'>
                                                <div className="h4 text-primary" id='heading'>
                                                  <img src={khanda} alt="" style={{ width: "50px", height: "50px" }} id="image1" /> GLOBAL SIKH MATRIMONY<img src={khanda} alt="" style={{ width: "50px", height: "50px" }} />
                                                </div>
                                              </div>
                                              <div className="h4 pb-2 mb-4 mt-4 text-primary border-bottom border-primary border-3 text-center">Applicant Profile</div>

                                              <form className="row g-3">
                                                <div className="" >
                                                  <img src={`https://api-gsm.sikhfoundation.co.in/uploads1/${val.profilephoto}`} className="img-thumbnail rounded mx-auto d-block" alt="..." style={{ width: "400px" }} />
                                                </div>
                                                <div className="col-md-4">
                                                  <label className="form-label fs-6">Applicant's First Name</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.candidate_firstname} />
                                                </div>
                                                <div className="col-md-4">
                                                  <label className="form-label fs-6">Applicant's Middle Name</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.candidate_middlename} />
                                                </div>
                                                <div className="col-md-4">
                                                  <label className="form-label fs-6">Applicant's Last Name</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.candidate_lastname} />
                                                </div>


                                                <div className="col-md-12">
                                                  <label className="form-label fs-6">Applicant's Current Address</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Candidate_Current_Address} />
                                                </div>



                                                <div className="col-md-3">
                                                  <label className="form-label fs-6">Date of Birth</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={new Date(val.Date_of_Birth).toLocaleDateString('en-GB')} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label className="form-label fs-6">Marital Status</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Marital_Status} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom04" className="form-label fs-6">Religion</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Religion} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom04" className="form-label fs-6">Gender</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Gender} />
                                                </div>




                                                <div className="col-md-4">
                                                  <label htmlFor="validationCustomUsername" className="form-label fs-6">Height</label>
                                                  <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">cm</span>
                                                    <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.height_cm} />
                                                    <div className="invalid-feedback">
                                                      Please choose a username.
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* <div className="col-md-2">
                                                  <label htmlFor="validationCustomUsername" className="form-label fs-6">Height</label>
                                                  <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">Feet</span>
                                                    <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.height_cm * 0.0328084} />
                                                    <div className="invalid-feedback">
                                                      Please choose a username.
                                                    </div>
                                                  </div>
                                                </div> */}
                                                <div className="col-md-4">
                                                  <label htmlFor="validationCustomUsername" className="form-label fs-6">Weight</label>
                                                  <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">Kg</span>
                                                    <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Weight} />
                                                    <div className="invalid-feedback">
                                                      Please choose a username.
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-md-4">
                                                  <label htmlFor="validationCustom01" className="form-label fs-6">Any Disability?</label>
                                                  <input className="form-control fs-5" type="text" placeholder="mental and/or physical" defaultValue={val.Any_Disability} />
                                                </div>



                                                <div className="col-md-4">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Mother Tongue</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Mother_Tongue} />
                                                </div>
                                                <div className="col-md-4">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Languages Known</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Languages_Known} />
                                                </div>
                                                <div className="col-md-4">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Nationality</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Nationality} />
                                                </div>




                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom01" className="form-label fs-6">WhatsApp number</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.WhatsApp_Number} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Email-ID</label>
                                                  <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                                                    <input className="form-control fs-5" type="email" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Email_ID} />
                                                    <div className="invalid-feedback">
                                                      Please choose a username.
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom01" className="form-label fs-6">Education</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Qualification} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom01" className="form-label fs-6">Highest Degree</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Degree} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Applicant's Occupation</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Candidate_Occupation} />
                                                </div>

                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Nature Of Work</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Nature_Of_Work} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Currency</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Currency} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustomUsername" className="form-label fs-6">Applicant's Annual Income</label>
                                                  <input type="number" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Candidate_Annual_Income} />
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                    <div className='container'>
                                      <div className='mx-2 mt-3'>
                                        <div className="h4 pb-2 mb-3 text-primary border-bottom border-primary border-3">Family & Relatives</div>



                                        <div className='row text-center mt-4'>
                                          <div className="col-md-2 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Relation Ship</label>
                                          </div>
                                          <div className="col-md-3 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Relative's Name</label>
                                          </div>
                                          <div className="col-md-2 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Age</label>
                                          </div>
                                          <div className="col-md-2 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Education</label>
                                          </div>
                                          <div className="col-md-3 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Occupation</label>
                                          </div>
                                          <hr className='d-md-none' />
                                        </div>
                                      </div>
                                    </div>
                                    {relative1Info.map((val, key) => {
                                      return (
                                        <>
                                          <div className='container'>
                                            <div className=''>
                                              <form className="row g-3">
                                                <div className='row g-3'>
                                                  <div className="col-md-2">
                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relationship} />
                                                  </div>

                                                  <div className="col-md-3">
                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relative_name} />
                                                  </div>

                                                  <div className="col-md-2">
                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relative_age} />
                                                  </div>

                                                  <div className="col-md-2">
                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relative_Education} />
                                                  </div>
                                                  <div className="col-md-3">
                                                    <input className="form-control fs-5" type="text" defaultValue={val.Relative_Degree} />
                                                  </div>

                                                  <hr className='d-md-none' />
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                    {data.map((val, key) => {
                                      return (
                                        <>
                                          <div className='container'>
                                            <div className='m-3'>
                                              <form className="row g-3">
                                                <div className="col-md-9">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Father's / Family Permanent Address</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.family_permanent_address} />
                                                </div>
                                                <div className="col-md-3">
                                                  <label htmlFor="validationCustomUsername" className="form-label fs-6">Family's Annual Income</label>
                                                  <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">₹</span>
                                                    <input type="number" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Family_Annual_Income} />
                                                    <div className="invalid-feedback">
                                                      Please choose a username.
                                                    </div>
                                                  </div>
                                                </div>
                                              </form>
                                            </div>
                                          </div>

                                          <div className='container'>
                                            <div className='m-3'>
                                              <form className="row g-3">
                                                <div className="h4 pb-2 my-4 text-primary border-bottom border-primary border-3">Gurudwara Details</div>

                                                <div className="col-md-12">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Gurudwara Normally visited : Name & Address</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.Gurudwara_Normally_visited} />
                                                </div>
                                                <div className="col-md-6">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Name of Contact Person at Gurudwara</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.G_Contact_Person} />
                                                </div>
                                                <div className="col-md-6">
                                                  <label htmlFor="validationCustom02" className="form-label fs-6">Phone no. of contact person</label>
                                                  <input className="form-control fs-5" type="text" defaultValue={val.G_Phone_Number} />
                                                </div>
                                                {val.additional_information.length == 0 ?
                                                  <div></div>
                                                  :
                                                  <div>
                                                    <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary">Any Other Information</div>
                                                    <label htmlFor="validationCustom02" className="form-label fs-6">Other information</label>

                                                    <div className="col-md-12">
                                                      <p class="form-control" >{val.additional_information}</p>
                                                    </div>
                                                  </div>
                                                }
                                              </form>
                                            </div>
                                          </div>

                                        </>
                                      );
                                    })}
                                  </>
                                );
                              }
                            })}
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          })}

        </div>
      </div>
    </>
  )
}

export default IInterestedIn