import React, { useRef } from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import { Link } from "react-router-dom"; //router link
import khanda from "../Photos/khanda.png";
import "./applicant.css";
import axios from "axios";
// import ReCAPTCHA from "react-google-recaptcha"
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import heading from "../Photos/heading.png";

function ApplicantRegistration() {
  const [checkOtp, setCheckOtp] = useState(1213);
  const [phoneNo, setPhoneNo] = useState([]);
  const [recivedOtp, setRecivedOtp] = useState(1213);

  const info = () => {
    Axios.post("https://api-gsm.sikhfoundation.co.in/verifywhatsappnumber", {
      phoneNo: phoneNo,
    }).then((response) => {
      if (response.data === false) {
        setShow(true);
        setModalMessage("This WhatsApp number is already registered.");
      } else {
        setShow(true);
        setModalMessage("OTP sent to your whatsapp number");
        setRecivedOtp(response.data);
        console.log(response.data);
      }
    });
  };

  const history = useHistory();
  const gotodashboard = () => {
    history.push("/dashboard", { verified: true });
  }

  const check = () => {
    if (checkOtp == recivedOtp) {
      document.getElementById("formform").hidden = false;
      document.getElementById("otpotp").hidden = true;
    } else {
      console.log("false");
      setShow(true);
      setModalMessage("OTP incorrect. Try again...");
    }
  };

  const [CandidateID, setCandidateID] = useState("");
  const [CandidateFullName, setCandidateFullName] = useState("");
  const [candidatelastName, setCandidatelastName] = useState("");
  const [candidateSurname, setCandidateSurname] = useState("");
  const [CandidateCurrentAddress, setCandidateCurrentAddress] = useState("");
  const [DateOfBirth, setDateOfBirth] = useState("");
  const [MaritalStatus, setMaritalStatus] = useState("");
  const [Religion, setReligion] = useState("");
  const [Gender, setGender] = useState("");
  const [Height, setHeight] = useState("");
  const [Weight, setWeight] = useState("");
  const [AnyDisability, setAnyDisability] = useState("");
  const [MotherTonge, setMotherTonge] = useState("");
  const [LanguagesKnown, setLanguagesKnown] = useState("");
  const [Nationality, setNationality] = useState("");
  const [WhatsappNumber, setWhatsappNumber] = useState("");
  const [Email, setEmail] = useState("");
  const [degree, setDegree] = useState("");
  const [HighestEducation, setHighestEducation] = useState("");
  const [CandidateOccupation, setCandidateOccupation] = useState("");
  const [NatureOfWork, setNatureOfWork] = useState("");
  const [Currency, setcurrency] = useState("");
  const [CandidateAnnualIncome, setCandidateAnnualIncome] = useState("");
  const [FatherAddress, setFatherAddress] = useState("");
  const [FamilyAnnualIncome, setFamilyAnnualIncome] = useState("");
  const [GurudwaraVisited, setGurudwaraVisited] = useState("");
  const [GcontactPerson, setGcontactPerson] = useState("");
  const [GcontactNumber, setGcontactNumber] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [fatherAge, setFatherAge] = useState("");
  const [fatherEducation, setFatherEducation] = useState("");
  const [fatherDegree, setFatherDegree] = useState("");
  const [mothername, setMotherName] = useState("");
  const [motherage, setMotherAge] = useState("");
  const [motherEducation, setMotherEducation] = useState("");
  const [motherDegree, setMotherDegree] = useState("");
  const [relative3, setRelative3] = useState("");
  const [relative3name, setRelative3Name] = useState("");
  const [relative3age, setRelative3Age] = useState("");
  const [relative3Education, setRelative3Education] = useState("");
  const [relative3Degree, setRelative3Degree] = useState("");
  const [relative4, setRelative4] = useState("");
  const [relative4name, setRelative4Name] = useState("");
  const [relative4age, setRelative4Age] = useState("");
  const [relative4Education, setRelative4Education] = useState("");
  const [relative4Degree, setRelative4Degree] = useState("");
  const [relative5, setRelative5] = useState("");
  const [relative5name, setRelative5Name] = useState("");
  const [relative5age, setRelative5Age] = useState("");
  const [relative5Education, setRelative5Education] = useState("");
  const [relative5Degree, setRelative5Degree] = useState("");
  const [relative6, setRelative6] = useState("");
  const [relative6name, setRelative6Name] = useState("");
  const [relative6age, setRelative6Age] = useState("");
  const [relative6Education, setRelative6Education] = useState("");
  const [relative6Degree, setRelative6Degree] = useState("");
  const [relative7, setRelative7] = useState("");
  const [relative7name, setRelative7Name] = useState("");
  const [relative7age, setRelative7Age] = useState("");
  const [relative7Education, setRelative7Education] = useState("");
  const [relative7Degree, setRelative7Degree] = useState("");
  const [remarks, setRemarks] = useState("");
  const [referenceName, setReferenceName] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");

  useEffect(() => {
    let d = new Date();
    let cid = d.getTime();
    let cid2 = cid.toString();
    setCandidateID(cid2.slice(-6));
  }, []);

  console.log(CandidateID);

  const saveapplicant = async (e) => {
    e.preventDefault();
    console.log("candidateId before Axios post", CandidateID);

    Axios.post("https://api-gsm.sikhfoundation.co.in/upload", {
      CandidateID: CandidateID,
      CandidateFullName: CandidateFullName,
      candidatelastName: candidatelastName,
      candidateSurname: candidateSurname,
      CandidateCurrentAddress: CandidateCurrentAddress,
      DateOfBirth: DateOfBirth,
      MaritalStatus: MaritalStatus,
      Religion: Religion,
      Gender: Gender,
      Height: Height,
      Weight: Weight,
      AnyDisability: AnyDisability,
      MotherTonge: MotherTonge,
      LanguagesKnown: LanguagesKnown,
      Nationality: Nationality,
      WhatsappNumber: WhatsappNumber,
      Email: Email,
      degree: degree,
      HighestEducation: HighestEducation,
      CandidateOccupation: CandidateOccupation,
      NatureOfWork: NatureOfWork,
      Currency: Currency,
      CandidateAnnualIncome: CandidateAnnualIncome,
      FatherAddress: FatherAddress,
      FamilyAnnualIncome: FamilyAnnualIncome,
      GurudwaraVisited: GurudwaraVisited,
      GcontactPerson: GcontactPerson,
      GcontactNumber: GcontactNumber,
      fatherName: fatherName,
      fatherAge: fatherAge,
      fatherEducation: fatherEducation,
      fatherDegree: fatherDegree,
      mothername: mothername,
      motherage: motherage,
      motherEducation: motherEducation,
      motherDegree: motherDegree,
      relative3: relative3,
      relative3name: relative3name,
      relative3age: relative3age,
      relative3Education: relative3Education,
      relative3Degree: relative3Degree,
      relative4: relative4,
      relative4name: relative4name,
      relative4age: relative4age,
      relative4Education: relative4Education,
      relative4Degree: relative4Degree,
      relative5: relative5,
      relative5name: relative5name,
      relative5age: relative5age,
      relative5Education: relative5Education,
      relative5Degree: relative5Degree,
      relative6: relative6,
      relative6name: relative6name,
      relative6age: relative6age,
      relative6Education: relative6Education,
      relative6Degree: relative6Degree,
      relative7: relative7,
      relative7name: relative7name,
      relative7age: relative7age,
      relative7Education: relative7Education,
      relative7Degree: relative7Degree,
      remarks: remarks,
      referenceName: referenceName,
      referenceNumber: referenceNumber,
    }).then(
      Axios.post("https://api-gsm.sikhfoundation.co.in/sendcandidateidonw", {
        CandidateNumber: phoneNo,
        CandidateID: CandidateID,
      }).catch((error) => console.log("error", error))
    );
    setShow(true);
    setModalMessage(
      "Form submitted successfully. Applicant Id sent to your Whatsapp number. Login-password will be sent when the profile is approved."
    );
  };

  const [cmValue, setCmValue] = useState(0);
  const [feet, setFeetValue] = useState(0);
  const cmToFeet = (cm) => {
    const totalInches = Math.round(cm / 2.54);
    const feet = Math.floor(totalInches / 12);
    const inches = totalInches % 12;
    return `${feet} feet ${inches} inches`;
  };

  const [modalMessage, setModalMessage] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  return (
    <div className="container">
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="text-primary">GSM says</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-primary">{modalMessage}</Modal.Body>
        <Modal.Footer>
          {modalMessage ==
            "Form submitted successfully. Applicant Id sent to your Whatsapp number. Login-password will be sent when the profile is approved." ? (
              // "Form submitted successfully. Applicant Id sent to your Whatsapp number. Login-password will be sent when the profile is approved." ? (
              <Link
                className="btn btn-primary"
                to={{
                  pathname: "/uploadformphoto",
                  state: {
                    CandidateID,
                    phoneNo,
                    CandidateFullName,
                    candidatelastName,
                    candidateSurname,
                    referenceName,
                    referenceNumber,
                    Gender,
                    isEdit: false,
                    countofsendingreference:0,
                  },
                }}
              >
                OK
              </Link>
            ) : (
              <Button variant="primary" onClick={handleClose} autoFocus>
                Ok
              </Button>
            )}
          {modalMessage ==
            "This WhatsApp number is already registered." ? (
            <Link
              className="btn btn-primary"
              to="/CheckApplicationStatus"
            >
              Check Application Status
            </Link>
          ) : <div></div>}
        </Modal.Footer>
      </Modal>
      <div
        className="mt-2 text-primary text-center sticky-top border-bottom border-primary border-2"
        style={{ backgroundColor: "white" }}
      >
        <img src={heading} alt="" className="col-md-5 text-center img-fluid" />
      </div>
      <div className="mx-3 my-1">
        <div className="row g-3" id="otpotp">
          <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary border-3 d-flex flex-wrap justify-content-between align-items-center">
            <span>
              Please provide <u>your Whatsapp phone number</u> to be used for
              future communication and follow-up
            </span>
            <Link
              className="mt-2 btn btn-primary order-last order-md-last"
              onClick={gotodashboard}
            >
              <i className="bi bi-caret-left-fill mx-2"></i> Back
            </Link>
          </div>

          <div className="col-md-3">
            <input
              className="form-control"
              id="input"
              type="number"
              placeholder="Enter WhatsApp number"
              onChange={(event) => {
                setPhoneNo(event.target.value);
                setWhatsappNumber(event.target.value);
              }}
              autoComplete="off"
              autoFocus
            />
          </div>
          <div className="col-md-9">
            <Link
              to="/ApplicantRegistration"
              className="btn btn-primary"
              onClick={info}
            >
              Get OTP
            </Link>
          </div>
          <div className="col-md-3">
            <input
              className="form-control"
              type="number"
              placeholder="Enter OTP"
              onChange={(event) => {
                setCheckOtp(event.target.value);
              }}
            />
          </div>
          <div className="col-md-3 mb-4">
            <Link
              to="/ApplicantRegistration"
              className="btn btn-primary"
              onClick={check}
            >
              Verify OTP
            </Link>
          </div>
        </div>

        <form
          onLoad="genCandidateID"
          className="row g-3"
          onSubmit={saveapplicant}
          id="formform"
          hidden
        >
          <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary border-3">
            Application Form
          </div>

          <div className="col-md-4">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Applicant's First Name <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              onChange={(event) => {
                setCandidateFullName(event.target.value);
              }}
              required
              autoFocus
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Applicant's Middle Name <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              onChange={(event) => {
                setCandidatelastName(event.target.value);
              }}
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Applicant's Last Name <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              onChange={(event) => {
                setCandidateSurname(event.target.value);
              }}
              required
            />
          </div>

          <div className="col-md-12">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Applicant's Current Address <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              onChange={(event) => {
                setCandidateCurrentAddress(event.target.value);
              }}
              required
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Date of Birth <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="date"
              onChange={(event) => {
                setDateOfBirth(event.target.value);
              }}
              required
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom04" className="form-label fs-5">
              Marital Status <span className="text-danger">*</span>
            </label>
            <select
              className="form-select"
              onChange={(event) => {
                setMaritalStatus(event.target.value);
              }}
            >
              <option value="DEFAULT" selected disabled>
                Never married / Divorcee / Widow/Widower
              </option>
              <option>Never married</option>
              <option>Divorcee</option>
              <option>Widow/Widower</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom04" className="form-label fs-5">
              Religion <span className="text-danger">*</span>
            </label>
            <select
              className="form-select"
              onChange={(event) => {
                setReligion(event.target.value);
              }}
              required
            >
              <option value="DEFAULT" selected disabled>
                Sikh / Keshdhari / Amritdhari
              </option>
              <option>Sikh (Keshdhari)</option>
              <option>Sikh (Amritdhari)</option>
              <option>Sikh</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom04" className="form-label fs-5">
              Gender <span className="text-danger">*</span>
            </label>
            <select
              className="form-select"
              onChange={(event) => {
                setGender(event.target.value);
              }}
              required
            >
              <option value="DEFAULT" selected disabled>
                Male / Female
              </option>
              <option>Male</option>
              <option>Female</option>
            </select>
          </div>

          <div className="col-md-4">
            <label
              htmlFor="validationCustomUsername"
              className="form-label fs-5"
            >
              Height : {feet} <span className="text-danger">*</span>
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupPrepend">
                cm
              </span>
              <input
                type="number"
                className="form-control"
                aria-describedby="inputGroupPrepend"
                onChange={(event) => {
                  setHeight(event.target.value);
                  const cm = event.target.value;
                  const feet = cmToFeet(cm);
                  setCmValue(cm);
                  setFeetValue(feet);
                }}
                required
              />
              <div className="invalid-feedback">Please choose a username.</div>
            </div>
          </div>

          <div className="col-md-4">
            <label
              htmlFor="validationCustomUsername"
              className="form-label fs-5"
            >
              Weight <span className="text-danger">*</span>
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupPrepend">
                kg
              </span>
              <input
                type="number"
                className="form-control"
                aria-describedby="inputGroupPrepend"
                onChange={(event) => {
                  setWeight(event.target.value);
                }}
                required
              />
              <div className="invalid-feedback">Please choose a username.</div>
            </div>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Any Disability?
            </label>
            <input
              className="form-control"
              type="text"
              onChange={(event) => {
                setAnyDisability(event.target.value);
              }}
              placeholder="mental and/or physical"
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Mother Tongue <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              onChange={(event) => {
                setMotherTonge(event.target.value);
              }}
              required
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Languages Known
            </label>
            <input
              className="form-control"
              type="text"
              onChange={(event) => {
                setLanguagesKnown(event.target.value);
              }}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Nationality <span className="text-danger">*</span>
            </label>
            <input
              className="form-control"
              type="text"
              onChange={(event) => {
                setNationality(event.target.value);
              }}
              required
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="validationCustom01" className="form-label fs-5">
              Verified Phone Number
            </label>
            <input
              className="form-control"
              type="number"
              disabled
              value={phoneNo}
            />
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Email-ID
            </label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupPrepend">
                @
              </span>
              <input
                className="form-control"
                type="email"
                aria-describedby="inputGroupPrepend"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              <div className="invalid-feedback">Please choose a username.</div>
            </div>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom04" className="form-label fs-5">
              Education <span className="text-danger">*</span>
            </label>
            <select
              className="form-select"
              onChange={(event) => {
                setDegree(event.target.value);
              }}
              required
            >
              <option value="DEFAULT" selected disabled>
                Select Degree
              </option>
              <option>Under Graduate</option>
              <option>Graduate</option>
              <option>Post Graduate</option>
            </select>
          </div>
          <div className="col-md-3">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Highest Degree
            </label>
            <input
              className="form-control"
              type="text"
              onChange={(event) => {
                setHighestEducation(event.target.value);
              }}
            />
          </div>

          <div className="col-md-3">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Applicant's Occupation <span className="text-danger">*</span>
            </label>
            <select
              className="form-select"
              onChange={(event) => {
                setCandidateOccupation(event.target.value);
              }}
              required
            >
              <option value="DEFAULT" selected disabled>
                Select Occupation
              </option>
              <option>Businessman</option>
              <option>Professional</option>
              <option>Govt. Service</option>
              <option>Private Service</option>
              <option>Serving Abroad</option>
              <option>Other</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="validationCustom02" className="form-label fs-5">
              Nature Of Work
            </label>
            {/* <input className="form-control" type="text" onChange={(event) => { setNatureOfWork(event.target.value) }} /> */}
            <select
              className="form-select"
              onChange={(event) => {
                setNatureOfWork(event.target.value);
              }}
            >
              <option value="DEFAULT" selected disabled>
                IT / Medical / Engineering / Other
              </option>
              <option>IT</option>
              <option>Medical</option>
              <option>Engineering</option>
              <option>Other</option>
            </select>
          </div>
          <div className="col-md-2">
            <label
              htmlFor="validationCustomUsername"
              className="form-label fs-5"
            >
              Currency <span className="text-danger">*</span>
            </label>
            <select
              className="form-select"
              onChange={(event) => {
                setcurrency(event.target.value);
              }}
              required
            >
              <option value="DEFAULT" selected disabled>
                INR / USD / AUD
              </option>
              <option>INR - ₹</option>
              <option>AUD - $</option>
              <option>CAD - $</option>
              <option>LKR - ₨</option>
              <option>ARS - $</option>
              <option>BDT - ৳</option>
              <option>BTN - Nu.</option>
              <option>AFN - ؋</option>
              <option>BRL - R$</option>
              <option>KHR - ៛</option>
              <option>CNY - ¥</option>
              <option>COP - $</option>
              <option>DKK - kr</option>
              <option>EGP - £</option>
              <option>HKD - $</option>
              <option>IDR - Rp</option>
              <option>IRR - ﷼</option>
              <option>ILS - ₪</option>
              <option>JPY - ¥</option>
              <option>JEP - £</option>
              <option>KPW - ₩</option>
              <option>KRW - ₩</option>
              <option>LRD - $</option>
              <option>MYR - RM</option>
              <option>MXN - $</option>
              <option>NPR - ₨</option>
              <option>NGN - ₦</option>
              <option>NOK - kr</option>
              <option>OMR - ﷼</option>
              <option>PKR - ₨</option>
              <option>PHP - ₱</option>
              <option>PLN - zł</option>
              <option>QAR - ﷼</option>
              <option>RUB - ₽</option>
              <option>SAR - ﷼</option>
              <option>RSD - Дин.</option>
              <option>SGD - $</option>
              <option>ZAR - R</option>
              <option>SEK - kr</option>
              <option>CHF - CHF</option>
              <option>TWD - NT$</option>
              <option>THB - ฿</option>
              <option>UAH - ₴</option>
              <option>GBP - £</option>
              <option>YER - ﷼</option>
              <option>ZWD - Z$</option>
            </select>
          </div>
          <div className="col-md-3">
            <label
              htmlFor="validationCustomUsername"
              className="form-label fs-5"
            >
              Applicant's Annual Income <span className="text-danger">*</span>
            </label>
            <div className="input-group has-validation">
              <input
                type="number"
                className="form-control"
                id="validationCustomUsername"
                aria-describedby="inputGroupPrepend"
                onChange={(event) => {
                  setCandidateAnnualIncome(event.target.value);
                }}
                required
              />
              <div className="invalid-feedback">Please choose a username.</div>
            </div>
          </div>

          <div className="mt-3">
            <div className="h4 pb-2 mb-4 text-primary border-bottom border-primary">
              Family & Relatives
            </div>

            <div className="row g-3">
              {/* line1 */}
              <div className="col-md-2">
                <input
                  className="form-control"
                  type="text"
                  value="Father"
                  disabled
                />
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Father Name"
                  onChange={(event) => {
                    setFatherName(event.target.value);
                  }}
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="Father Age"
                  onChange={(event) => {
                    setFatherAge(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                {/* <input className="form-control" type="number" placeholder='Education'  /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setFatherEducation(event.target.value);
                  }}
                >
                  {/* <option selected disabled value="">Male / Female</option> */}
                  <option value="DEFAULT" selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                {/* <input className="form-control" type="text" placeholder='Degree' onChange={(event) => { setFatherDegree(event.target.value) }} /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setFatherDegree(event.target.value);
                  }}
                >
                  <option value="DEFAULT" selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              {/* <div className="col-md-3">
                                <input className="form-control" type="number" placeholder='Father Income' onChange={(event) => { setFatherIncome(event.target.value) }} />
                            </div> */}

              <hr className="d-md-none" />

              {/* line 2 */}
              <div className="col-md-2">
                <input
                  className="form-control"
                  type="text"
                  value="Mother"
                  disabled
                />
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Mother Name"
                  onChange={(event) => {
                    setMotherName(event.target.value);
                  }}
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="Mother Age"
                  onChange={(event) => {
                    setMotherAge(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                {/* <input className="form-control" type="number" placeholder='Education'  /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setMotherEducation(event.target.value);
                  }}
                >
                  {/* <option selected disabled value="">Male / Female</option> */}
                  <option value="DEFAULT" selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                {/* <input className="form-control" type="text" placeholder='Degree' onChange={(event) => { setMotherDegree(event.target.value) }} /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setMotherDegree(event.target.value);
                  }}
                >
                  <option value="DEFAULT" selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              <hr className="d-md-none" />

              {/* line3 */}
              <div className="col-md-2">
                {/* <input className="form-control" type="text" placeholder="RelationShip 1" onChange={(event) => { setRelative3(event.target.value) }} /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative3(event.target.value);
                  }}
                >
                  {/* <option selected disabled value="">Male / Female</option> */}
                  <option value="DEFAULT" selected disabled>
                    Brother / Sister
                  </option>
                  <option>Brother</option>
                  <option>Sister</option>
                </select>
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="His/Her Name"
                  onChange={(event) => {
                    setRelative3Name(event.target.value);
                  }}
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="His/Her Age"
                  onChange={(event) => {
                    setRelative3Age(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                {/* <input className="form-control" type="number" placeholder='Education'  /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative3Education(event.target.value);
                  }}
                >
                  {/* <option selected disabled value="">Male / Female</option> */}
                  <option value="DEFAULT" selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                {/* <input className="form-control" type="text" placeholder='Degree' onChange={(event) => { setRelative3Degree(event.target.value) }} /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative3Degree(event.target.value);
                  }}
                >
                  <option value="DEFAULT" selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              <hr className="d-md-none" />

              {/* line4 */}
              <div className="col-md-2">
                {/* <input className="form-control" type="text" placeholder="RelationShip 2" onChange={(event) => { setRelative4(event.target.value) }} /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative4(event.target.value);
                  }}
                >
                  {/* <option selected disabled value="">Male / Female</option> */}
                  <option value="DEFAULT" selected disabled>
                    Brother / Sister
                  </option>
                  <option>Brother</option>
                  <option>Sister</option>
                </select>
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="His/Her Name"
                  onChange={(event) => {
                    setRelative4Name(event.target.value);
                  }}
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="His/Her Age"
                  onChange={(event) => {
                    setRelative4Age(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                {/* <input className="form-control" type="number" placeholder='Education'  /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative4Education(event.target.value);
                  }}
                >
                  {/* <option selected disabled value="">Male / Female</option> */}
                  <option value="DEFAULT" selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                {/* <input className="form-control" type="text" placeholder='Degree' onChange={(event) => { setRelative4Degree(event.target.value) }} /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative4Degree(event.target.value);
                  }}
                >
                  <option value="DEFAULT" selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              <hr className="d-md-none" />

              {/* line5 */}
              <div className="col-md-2">
                {/* <input className="form-control" type="text" placeholder="RelationShip 3" onChange={(event) => { setRelative5(event.target.value) }} /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative5(event.target.value);
                  }}
                >
                  {/* <option selected disabled value="">Male / Female</option> */}
                  <option value="DEFAULT" selected disabled>
                    Brother / Sister
                  </option>
                  <option>Brother</option>
                  <option>Sister</option>
                </select>
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="His/Her Name"
                  onChange={(event) => {
                    setRelative5Name(event.target.value);
                  }}
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="His/Her Age"
                  onChange={(event) => {
                    setRelative5Age(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                {/* <input className="form-control" type="number" placeholder='Education'  /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative5Education(event.target.value);
                  }}
                >
                  {/* <option selected disabled value="">Male / Female</option> */}
                  <option value="DEFAULT" selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                {/* <input className="form-control" type="text" placeholder='Degree' onChange={(event) => { setRelative5Degree(event.target.value) }} /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative5Degree(event.target.value);
                  }}
                >
                  <option value="DEFAULT" selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              <hr className="d-md-none" />

              {/* line6 */}
              <div className="col-md-2">
                {/* <input className="form-control" type="text" placeholder="RelationShip 4" onChange={(event) => { setRelative6(event.target.value) }} /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative6(event.target.value);
                  }}
                >
                  {/* <option selected disabled value="">Male / Female</option> */}
                  <option value="DEFAULT" selected disabled>
                    Brother / Sister
                  </option>
                  <option>Brother</option>
                  <option>Sister</option>
                </select>
              </div>

              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="His/Her Name"
                  onChange={(event) => {
                    setRelative6Name(event.target.value);
                  }}
                />
              </div>

              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="His/Her Age"
                  onChange={(event) => {
                    setRelative6Age(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                {/* <input className="form-control" type="number" placeholder='Education'  /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative6Education(event.target.value);
                  }}
                >
                  {/* <option selected disabled value="">Male / Female</option> */}
                  <option value="DEFAULT" selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                {/* <input className="form-control" type="text" placeholder='Degree' onChange={(event) => { setRelative6Degree(event.target.value) }} /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative6Degree(event.target.value);
                  }}
                >
                  <option value="DEFAULT" selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              <hr className="d-md-none" />

              {/* line7 */}

              <div className="col-md-2">
                {/* <input className="form-control" type="text" placeholder="RelationShip 5" onChange={(event) => { setRelative7(event.target.value) }} /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative7(event.target.value);
                  }}
                >
                  {/* <option selected disabled value="">Male / Female</option> */}
                  <option value="DEFAULT" selected disabled>
                    Brother / Sister
                  </option>
                  <option>Brother</option>
                  <option>Sister</option>
                </select>
              </div>
              <div className="col-md-3">
                <input
                  className="form-control"
                  type="text"
                  placeholder="His/Her Name"
                  onChange={(event) => {
                    setRelative7Name(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                <input
                  className="form-control"
                  type="number"
                  placeholder="His/Her Age"
                  onChange={(event) => {
                    setRelative7Age(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-2">
                {/* <input className="form-control" type="number" placeholder='Education'  /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative7Education(event.target.value);
                  }}
                >
                  {/* <option selected disabled value="">Male / Female</option> */}
                  <option value="DEFAULT" selected disabled>
                    Education
                  </option>
                  <option>Under Graduate</option>
                  <option>Graduate</option>
                  <option>Post Graduate</option>
                </select>
              </div>
              <div className="col-md-3">
                {/* <input className="form-control" type="text" placeholder='Degree' onChange={(event) => { setRelative7Degree(event.target.value) }} /> */}
                <select
                  className="form-select"
                  onChange={(event) => {
                    setRelative7Degree(event.target.value);
                  }}
                >
                  <option value="DEFAULT" selected disabled>
                    Occupation
                  </option>
                  <option>Businessman</option>
                  <option>Professional</option>
                  <option>Govt. Service</option>
                  <option>Private Service</option>
                  <option>Serving Abroad</option>
                  <option>Other</option>
                </select>
              </div>

              <hr className="d-md-none" />

              {/* line1 */}
              <div className="col-md-9">
                <label htmlFor="validationCustom02" className="form-label fs-5">
                  Father's / Family's Permanent Address
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(event) => {
                    setFatherAddress(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-3">
                <label
                  htmlFor="validationCustomUsername"
                  className="form-label fs-5"
                >
                  Family's Annual Income
                </label>
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">
                    ₹
                  </span>
                  <input
                    type="number"
                    className="form-control"
                    id="validationCustomUsername"
                    aria-describedby="inputGroupPrepend"
                    onChange={(event) => {
                      setFamilyAnnualIncome(event.target.value);
                    }}
                  />
                  <div className="invalid-feedback">
                    Please choose a username.
                  </div>
                </div>
              </div>

              {/* line2 */}
              <div className="h4 pb-2 mb-1 text-primary border-bottom border-primary">
                Gurudwara Details
              </div>

              <div className="col-md-6">
                <label htmlFor="validationCustom02" className="form-label">
                  Gurudwara Normally visited : Name & Address{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(event) => {
                    setGurudwaraVisited(event.target.value);
                  }}
                  required
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="validationCustom02" className="form-label">
                  Name of Contact Person at Gurudwara
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(event) => {
                    setGcontactPerson(event.target.value);
                  }}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="validationCustom02" className="form-label">
                  Phone no. of contact person
                </label>
                <input
                  className="form-control"
                  type="number"
                  onChange={(event) => {
                    setGcontactNumber(event.target.value);
                  }}
                />
              </div>

              <div className="h4 pb-2 mb-2 text-primary border-bottom border-primary">
                Any Other Information
              </div>

              <div className="col-md-12">
                {/* <input className="form-control" type="text" onChange={(event) => { setGurudwaraVisited(event.target.value) }} /> */}
                <textarea
                  className="form-control"
                  placeholder=""
                  onChange={(event) => {
                    setRemarks(event.target.value);
                  }}
                ></textarea>
              </div>

              {/* <div className="h4 text-primary border-bottom border-primary">
                <p>Reference details</p>
              </div> */}

              {/* <div className="h4 pb-2 text-primary border-bottom border-primary">
                Reference details<span className="h6 text-right"><i className="bi bi-exclamation-triangle-fill text-warning mx-3"></i>Kindly note: Your profile will be published only after the reference person confirms.</span>
              </div> */}

              {/* <div className="container text-primary border-bottom border-primary">
                <div className="row">
                  <div className="col-5 h4">
                    <p>Reference details:</p>
                  </div>
                  <div className="col text-right d-flex">
                  <i className="bi bi-exclamation-triangle-fill text-warning mx-3"></i><p className="">Kindly note: Your profile will be published only after the reference person confirms.</p>
                  </div>
                </div>
              </div>
               */}
              <div className="container text-primary border-bottom border-primary mt-3">
                <div className="row">
                  <div className="col-5 h4 d-flex align-items-center">
                    <p className="mb-0">Reference details:</p>
                  </div>
                  <div className="col text-right d-flex align-items-center">
                    <i className="bi bi-exclamation-triangle-fill text-warning mx-3"></i>
                    <p className="mb-0">
                      Kindly note: Your profile will be published only after the
                      reference person confirms.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <label htmlFor="validationCustom02" className="form-label">
                  Reference Person Name <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(event) => {
                    setReferenceName(event.target.value);
                  }}
                  required
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="validationCustom02" className="form-label">
                  Reference WhatsApp Number{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="number"
                  onChange={(event) => {
                    setReferenceNumber(event.target.value);
                  }}
                  required
                />
              </div>

              <div className="col-md-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    required
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    I have read and agree to the
                  </label>
                  <Link
                    type="button"
                    className="mx-2"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    style={{ textDecoration: "" }}
                  >
                    Terms And Conditions
                  </Link>
                </div>
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title fs-5 text-primary" id="exampleModalLabel">
                          Global Sikh Matrimony
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body text-primary">
                        <ul>
                          <li>
                            It is important for the Candidates, Applicants and their family members to note that this matrimonial service is a free service to the applicants / candidates. The purpose of this service is to provide a common platform for the members of our community.
                          </li>
                          <br />
                          <li>
                            It is the responsibility of the applicant / candidate to verify the authenticity of the information provided by other applicants / candidates. The Sikh Foundation, members of the Sikh Foundation, the developers of this portal take no responsibility regarding the authenticity of the information provided by any applicant / candidate.
                          </li>
                        </ul>

                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                        >
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div>
                        <ReCAPTCHA sitekey="6LeDQGwjAAAAAMAfe_CRFyKvkUMvgXWwMM4esHJ5" ref={captchaRef} size="large" className=" g-recaptcha mt-3" />
                    </div> */}

          <div className="col-12">
            {/* <Link to="/" className="btn btn-primary" onClick={saveapplicant}>Submit Form</Link> */}
            {/* <Link to="/" onClick={saveapplicant} className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal2">Submit Form</Link> */}
            <input
              type="submit"
              className="btn btn-primary"
              value="Save and Proceed"
            />

            <div
              className="modal fade"
              id="exampleModal2"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">
                      Applicant-ID assigned
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    Your Applicant-ID is: {CandidateID}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Ok
                    </button>
                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                  </div>
                </div>
              </div>
            </div>
            <Link
              onClick={gotodashboard}
              className="btn btn-primary mx-2"
              role="button"
            >
              Back
            </Link>
          </div>
        </form>
        {/* </div> */}
      </div>
    </div>
  );
}

export default ApplicantRegistration;
