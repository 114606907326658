import React, { useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import khanda from '../Photos/khanda.png';
import couple from '../Photos/couple.jpg'


function IRecommend() {
    const [CandidateID, setCandidateID] = useState("");
    const [candidateInfo, setCandidateInfo] = useState([]);
    const [relative1Info, setRelative1Info] = useState([]);
    const [data,setData] = useState([]);

    const approveapplicant = async () => {
        Axios.post("https://api-gsm.sikhfoundation.co.in/recommendapplicant", {
            CandidateID: CandidateID,
        }).then((response) => {
            setCandidateInfo(response.data);
        });
        Axios.post("https://api-gsm.sikhfoundation.co.in/recommendapplicantrelation1", {
            CandidateID: CandidateID,
        }).then((response) => {
            setRelative1Info(response.data);
        });
        Axios.post("https://api-gsm.sikhfoundation.co.in/getdata1", {
            CandidateID: CandidateID,
        }).then((response) => {
            setData(response.data);
            data.map((val,key) => {console.log(val.Candidate_Photo)})
        });

    //     const res = Axios.post("https://api-gsm.sikhfoundation.co.in/getdata1", {
    //         CandidateID: CandidateID,
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     }).then((response) => {
    //              setData(response.data);
    //             //  data.map((val,key) => {console.log(val.Candidate_Photo)})
    //          });

    //     if (res.data.status == 201) {
    //         console.log("data get");
    //         setData(res.data.data)
    //         console.log(JSON.stringify(res.data.data))
    //         // data.map((val, key) => {
    //             // console.log(val.Candidate_Photo)
    //             // console.log(`https://api-gsm.sikhfoundation.co.in/uploads1/${val.Candidate_Photo}`)
    //         // })

    //     } else {
    //         console.log("error")
    //     }
    };

    const irecommend = () => {
        Axios.post("https://api-gsm.sikhfoundation.co.in/irecommend", {
            CandidateID: CandidateID,
        })
    };

    return (
        <>
            <div className="mx-3 mt-4">
                <form>
                    <div className='' id='heading1'>
                        <div className="h4 p-3 text-primary" id='heading'>
                            <img src={khanda} alt="" style={{ width: "50px", height: "50px" }} id="image1" /> GLOBAL SIKH MATRIMONY<img src={khanda} alt="" style={{ width: "50px", height: "50px" }} />
                        </div>
                    </div>
                    <div className="mx-5 col-md-3">
                        <label className="form-label">Candidate ID</label>
                        <input type="text" className="form-control" onChange={(event) => { setCandidateID(event.target.value) }} />
                    </div>
                    <button type="button" className="btn btn-primary mx-5 mt-3" onClick={approveapplicant} data-bs-toggle="modal" data-bs-target="#exampleModal">Show Profile</button>
                </form>
            </div>



            <div className="modal fade modal-xl" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body modal-dialog modal-dialog-scrollable">
                            {candidateInfo.map((val, key) => {
                                return (
                                    <>
                                        <div className=''>
                                            <div className=''>
                                                <div className='col-md-12 text-center' id='heading1'>
                                                    <div className="h4 text-primary" id='heading'>
                                                        <img src={khanda} alt="" style={{ width: "50px", height: "50px" }} id="image1" /> GLOBAL SIKH MATRIMONY<img src={khanda} alt="" style={{ width: "50px", height: "50px" }} />
                                                    </div>
                                                </div>
                                                <div className="h4 pb-2 mb-4 mt-4 text-primary border-bottom border-primary border-3 text-center">Applicant Profile</div>

                                                <form className="row g-3">
                                                    <div className="" >
                                                        {/* <img src="https://api-gsm.sikhfoundation.co.in/uploads1/abc.jpg" className="img-thumbnail rounded mx-auto d-block" alt="..." style={{ width: "400px", height: "400px" }} /> */}
                                                        {/* <img src={couple} className="img-thumbnail rounded mx-auto d-block" alt="..." style={{width:"400px"}}/> */}
                                                        {data.map((val, key) => {
                                                            return (
                                                                <>
                                                                    <img src={`https://api-gsm.sikhfoundation.co.in/uploads1/${val.Candidate_Photo}`} alt="hello" className="img-thumbnail rounded mx-auto d-block" style={{ width: "400px" }} />
                                                                </>
                                                            );
                                                        })}
                                                    </div>
                                                    {/* line 1 */}
                                                    <div className="col-md-4">
                                                        <label className="form-label fs-6">Candidate's Full Name</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.candidate_firstname} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <label className="form-label fs-6">Candidate's Current Address</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.Candidate_Current_Address} />
                                                    </div>



                                                    {/* line 2 */}
                                                    <div className="col-md-3">
                                                        <label className="form-label fs-6">Date of Birth</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.Date_of_Birth} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label className="form-label fs-6">Marital Status</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.Marital_Status} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="validationCustom04" className="form-label fs-6">Religion</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.Religion} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="validationCustom04" className="form-label fs-6">Gender</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.Gender} />
                                                    </div>



                                                    {/* line 3 */}

                                                    <div className="col-md-4">
                                                        <label htmlFor="validationCustomUsername" className="form-label fs-6">Height</label>
                                                        <div className="input-group has-validation">
                                                            <span className="input-group-text" id="inputGroupPrepend">Foot</span>
                                                            <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.height_cm} />
                                                            <div className="invalid-feedback">
                                                                Please choose a username.
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="validationCustomUsername" className="form-label fs-6">Weight</label>
                                                        <div className="input-group has-validation">
                                                            <span className="input-group-text" id="inputGroupPrepend">Kg</span>
                                                            <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Weight} />
                                                            <div className="invalid-feedback">
                                                                Please choose a username.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="validationCustom01" className="form-label fs-6">Any Disability?</label>
                                                        <input className="form-control fs-5" type="text" placeholder="mental and/or physical" defaultValue={val.Any_Disability} />
                                                    </div>


                                                    {/* line 4 */}

                                                    <div className="col-md-4">
                                                        <label htmlFor="validationCustom02" className="form-label fs-6">Mother Tongue</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.Mother_Tongue} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="validationCustom02" className="form-label fs-6">Languages Known</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.Languages_Known} />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="validationCustom02" className="form-label fs-6">Nationality</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.Nationality} />
                                                    </div>




                                                    {/* line5 */}
                                                    <div className="col-md-6">
                                                        <label htmlFor="validationCustom01" className="form-label fs-6">Phone number for contact/WhatsApp</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.WhatsApp_Number} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="validationCustom02" className="form-label fs-6">Email-ID</label>
                                                        <div className="input-group has-validation">
                                                            <span className="input-group-text" id="inputGroupPrepend">@</span>
                                                            <input className="form-control fs-5" type="email" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Email_ID} />
                                                            <div className="invalid-feedback">
                                                                Please choose a username.
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* line 6 */}
                                                    <div className="col-md-3">
                                                        <label htmlFor="validationCustom02" className="form-label fs-6">Highest Education</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.Highest_Education} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="validationCustom02" className="form-label fs-6">Candidate's Occupation</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.Candidate_Occupation} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="validationCustom02" className="form-label fs-6">Nature Of Work</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.Nature_Of_Work} />

                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="validationCustomUsername" className="form-label fs-6">Candidate's Annual Income</label>
                                                        <div className="input-group has-validation">
                                                            <span className="input-group-text" id="inputGroupPrepend">₹</span>
                                                            <input type="text" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Candidate_Annual_Income} />
                                                            <div className="invalid-feedback">
                                                                Please choose a username.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}



                            <div className='container'>
                                <div className='mx-2 mt-3'>
                                    <div className="h4 pb-2 mb-3 text-primary border-bottom border-primary border-3">Family & Relatives</div>



                                    <div className='row text-center mt-4'>
                                        <div className="col-md-3 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Relation Ship</label>
                                        </div>
                                        <div className="col-md-3 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Relative's Name</label>
                                        </div>
                                        <div className="col-md-3 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Age</label>
                                        </div>
                                        <div className="col-md-3 d-md-block d-none">
                                            <label htmlFor="validationCustomUsername" className="form-label fs-5">Income</label>
                                        </div>
                                        <hr className='d-md-none' />
                                    </div>
                                </div>
                            </div>
                            {relative1Info.map((val, key) => {
                                return (
                                    <>
                                        <div className='container'>
                                            <div className=''>
                                                <form className="row g-3">
                                                    <div className='row g-3'>
                                                        <div className="col-md-3">
                                                            <input className="form-control fs-5" type="text" defaultValue={val.Relationship} />
                                                        </div>

                                                        <div className="col-md-3">
                                                            <input className="form-control fs-5" type="text" defaultValue={val.Relative_name} />
                                                        </div>

                                                        <div className="col-md-3">
                                                            <input className="form-control fs-5" type="text" defaultValue={val.Relative_age} />
                                                        </div>

                                                        <div className="col-md-3">
                                                            <input className="form-control fs-5" type="text" defaultValue={val.Relative_income} />
                                                        </div>

                                                        <hr className='d-md-none' />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}



                            {candidateInfo.map((val, key) => {
                                return (
                                    <>
                                        <div className='container'>
                                            <div className='m-3'>
                                                <form className="row g-3">
                                                    <div className="col-md-9">
                                                        <label htmlFor="validationCustom02" className="form-label fs-6">Father's / Family Permanent Address</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.family_permanent_address} />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <label htmlFor="validationCustomUsername" className="form-label fs-6">Family's Annual Income</label>
                                                        <div className="input-group has-validation">
                                                            <span className="input-group-text" id="inputGroupPrepend">₹</span>
                                                            <input type="number" className="form-control fs-5" id="validationCustomUsername" aria-describedby="inputGroupPrepend" defaultValue={val.Family_Annual_Income} />
                                                            <div className="invalid-feedback">
                                                                Please choose a username.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div className='container'>
                                            <div className='m-3'>
                                                <form className="row g-3">
                                                    <div className="h4 pb-2 my-4 text-primary border-bottom border-primary border-3">Gurudwara Details</div>

                                                    <div className="col-md-12">
                                                        <label htmlFor="validationCustom02" className="form-label fs-6">Gurudwara Normally visited : Name & Address</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.Gurudwara_Normally_visited} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="validationCustom02" className="form-label fs-6">Name of Contact Person at Gurudwara</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.G_Contact_Person} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="validationCustom02" className="form-label fs-6">Phone no. of contact person</label>
                                                        <input className="form-control fs-5" type="text" defaultValue={val.G_Phone_Number} />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}


                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <Link to="/IRecommend" className="btn btn-primary" onClick={irecommend} target="_parent">I Recommend</Link>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default IRecommend;